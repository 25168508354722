import { Injectable } from '@angular/core';
import {QuoteDetailsDialog} from "../components/dialogs/quote-details-dialog/quote-details-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "./api.service";
export interface Quote {
  id: number;
  ts: string;
  vehicle_id: number;
  statCode: number;
  mileage: number;
  priceGBP: number;
  priceEUR: number;
  currencyRate: number;
  total: number;
  nox: number;
  customs_cost: number;
  customs_rate: number;
  vat: number;
  vrtEuro: number;
  transport_cost: number;
  transport_distance: number;
  transport_origin: string;
  transport_destination: string;
  make: string;
  model: string;
  version: string;
  status: string;
  user: string;
}
@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  constructor(private dialog: MatDialog, private apiService: ApiService) { }
  openDialog(element: Quote) {
    this.dialog.open(QuoteDetailsDialog,{
      data: element
    });
  }
  async setQuoteStatus(value: string, element: Quote) {
    await this.apiService.setQuoteStatus(element.id, value);
  }
}
