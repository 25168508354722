<h2 mat-dialog-title>{{ data.make }} {{ data.model }}</h2>
<mat-dialog-content>
  <div role="table" class="searchResTable valsRight">
    <ng-container *ngFor="let res of data | formatRes: true | keyvalue">
      <ng-container *ngIf="res.value">
        <div role="rowheader" class="rowheader">{{ res.key }}</div>
        @if(userService.admin && res.key == 'Est. sales price IE (ex. VAT)') {
          <div role="cell" class="value" (click)="!loadingEquivs ? showEquivs(data.id) : null" [ngClass]="{clickable: !loadingEquivs}" [matTooltip]="'Click to see equivalent vehicles on the Irish market'"><mat-progress-spinner mode="indeterminate" diameter="12" *ngIf="loadingEquivs"></mat-progress-spinner>{{ res.value }}</div>
        } @else {
          <div role="cell" class="value">{{ res.value }}</div>
        }

      </ng-container>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <a [routerLink]="['/home/ccq',{'url': data.registrationNumber ?? getSource(data).url}]" (click)="dialog.closeAll()" mat-raised-button>Get an import quote on this vehicle</a>
  <a [routerLink]="['/home/cyc',{'url': data.registrationNumber ?? getSource(data).url}]" (click)="dialog.closeAll()" mat-raised-button>Do a history check on this vehicle</a>
  <a [href]="getSource(data).url" target="_blank" rel="noreferrer" mat-raised-button>Open this ad on {{getSource(data).source}}</a>
  @if (userService.admin) {
    <a (click)="maskEntry(data)" mat-raised-button color="warn">Hide this entry</a>
  }
</mat-dialog-actions>
