import { Component } from '@angular/core';
import {MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {VrmSearchComponent} from "../../../components/vrm-search/vrm-search.component";
import {StatbitsComponent} from "../../../components/statbits/statbits.component";
import {ProdDetailsComponent} from "../../../components/prod-details/prod-details.component";

@Component({
  selector: 'app-start',
  standalone: true,
    imports: [
        MatButton,
        RouterLink,
        VrmSearchComponent,
        StatbitsComponent,
        ProdDetailsComponent
    ],
  templateUrl: './start.component.html',
  styleUrl: './start.component.scss'
})
export class StartComponent {

}
