<section class="header">
  <h2>So Who is TheCarSite?</h2>
</section>
<section class="content">
  <div class="content-container">
    <app-about></app-about>
  </div>
  <button class="c2aLarge" color="primary" mat-raised-button routerLink="/register">Get started</button>
</section>
<section class="reviews">
  <h2>What Our Customers Just Like You Say</h2>
  <app-reviews></app-reviews>
</section>
