import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {DecimalPipe, KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {formatRes} from "../../../../pipes/format-res.pipe";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: "equiv-cars",
  templateUrl: "../equiv-cars/equiv-cars.html",
  styleUrl: "../equiv-cars/equiv-cars.scss",
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, DecimalPipe, KeyValuePipe, NgForOf, NgIf, formatRes, MatButtonModule, MatDialogActions],
})
export class EquivCarsDialog {
  protected readonly Number = Number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  getURL(element: any): string {
    if (element.source == "carsireland") {
      return `https://www.carsireland.ie/${element.id}`;
    } else if (element.source == "carzone") {
      return `https://www.carzone.ie/used-cars/${element.make}/${element.model}/fpa/${element.id}`;
    } else if (element.source == "donedeal") {
      return `https://www.donedeal.ie/cars-for-sale/a/${element.id}`;
    }
    return "";
  }
}
