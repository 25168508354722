<table mat-table [dataSource]="activity" matSort matSortActive="ts" matSortDirection="desc">
  <ng-container matColumnDef="ts">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
    <td mat-cell *matCellDef="let element"> {{element.ts | date: 'medium'}}</td>
  </ng-container>
  <ng-container matColumnDef="vrm">
    <th mat-header-cell *matHeaderCellDef>VRM</th>
    <td mat-cell *matCellDef="let element"> {{element.vrm}}</td>
  </ng-container>
  <ng-container matColumnDef="endpoint">
    <th mat-header-cell *matHeaderCellDef>Activity</th>
    <td mat-cell *matCellDef="let element"> {{getActivity(element) }}</td>
  </ng-container>
  <ng-container matColumnDef="vehicle">
    <th mat-header-cell *matHeaderCellDef>Vehicle</th>
    <td mat-cell *matCellDef="let element"> <strong>{{element.make}}</strong> {{element.model_tfl  ?? element.versionData ?? element.model}}</td>
  </ng-container>
  <ng-container matColumnDef="credits">
    <th mat-header-cell *matHeaderCellDef>Credits</th>
    <td mat-cell *matCellDef="let element"> {{element.credits}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="action(element)" class="clickable"></tr>
</table>
<mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>
