import {EventEmitter, Injectable} from "@angular/core";
import {FirebaseError} from "@angular/fire/app/firebase";
import {
  Auth,
  IdTokenResult,
  User,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail, updateProfile

} from "@angular/fire/auth";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {ApiService} from "./api.service";
import {HttpClient} from "@angular/common/http";
import {LocaleService} from "./locale.service";

interface BusinessDetails {
  "name": string,
  "business_name": string,
  "business_address": string,
  "business_phone": string,
  "business_vat": string,
  "locale": "en-IE" | "en-GB",
  "stripe_id":string,
  "postcode": string,
  "city": string,
  "country": string
}

@Injectable({
  providedIn: "root"
})
export class UserService {
  public loginEvent = new EventEmitter<any>();
  public isAuthenticated = false;
  private user: User | null = null;
  private _idToken: IdTokenResult | undefined;
  private providers = {
    "google": new GoogleAuthProvider(),
    // 'ms': new OAuthProvider()
  };
  public businessDetails: BusinessDetails | undefined;

  constructor(private auth: Auth, private snackBar: MatSnackBar, private router: Router, private http: HttpClient, private localeService: LocaleService) {
    this.auth.onAuthStateChanged(async (user) => {
      // console.log('Auth state changed: ', user);
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/v8/firebase.User
        this.user = user;
        await this.getIdToken();
        this.isAuthenticated = true;
        this.loginEvent.emit(user);
      } else {
        // User is signed out
        this.user = null;
        this._idToken = undefined;
        this.isAuthenticated = false;
        this.loginEvent.emit(null);
      }
    });
  }
  public get name(): string {
      return this.user?.displayName || "";
  }
  public get quota(): number {
    return Number(this.getClaim('quota'));
  }
  public get quota_extra(): number {
    return Number(this.getClaim('quota_extra'));
  }
  public async getBusinessDetails(): Promise<BusinessDetails> {
    if (this.businessDetails) {
      return this.businessDetails;
    }
    const apiService = new ApiService(this.http,this,this.localeService);
    const d = await apiService.getBusinessDetails();
    if (d?.success && d?.data) {
      this.businessDetails = d.data;
      return d.data;
    }
    throw new Error("Business details not found");
  }

  /**
   * Update the user's display name
   * @param name
   */
  public setName(name: string) {
    if (this.user) {
      return updateProfile(this.user, {displayName: name});
    }
    throw new Error("User not logged in.");
  }
  public get email(): string {
      return this.user?.email || "";
  }
  public get admin(): boolean {
    return this.checkClaim("admin");
  }
  public get editor(): boolean {
    return this.checkClaim("editor");
  }

  /**
   * Login user using Firebase Auth
   * @param username
   * @param password
   */
  public async login(username: string, password: string) {
    if (!username || !password) {
      return;
    }
    await signInWithEmailAndPassword(this.auth, username, password)
      .catch((error: FirebaseError) => {
        this.snackBar.open(this.errorText(error.code), undefined, {panelClass: "snack-error", duration: 5000});
      });
  }

  /**
   * Register user using Firebase Auth
   * @param username
   * @param password
   */
  public async register(username: string, password: string) {
    const userCredential = await createUserWithEmailAndPassword(this.auth, username, password)
      .catch(error => {
        this.snackBar.open(this.errorText(error.code), undefined, {panelClass: "snack-error", duration: 5000, verticalPosition: "top"});
      })
    if (userCredential) {
      // Signed in
      const user = userCredential.user;
      // Check if we need to validate e-mail
      if (!user.emailVerified) {
        sendEmailVerification(user)
          .catch(e => console.error(e));
      }
    }
  }
  public signinGoogle() {
    signInWithPopup(this.auth,new GoogleAuthProvider());
  }
 public signinMicrosoft() {
    signInWithPopup(this.auth,new OAuthProvider("microsoft.com"));
  }

  public isLoggedIn() {
    return this.user !== null;
  }
  public getClaim(claim: string): string | null {
    if (this._idToken) {
      // console.log("claims",this._idToken.claims);
      return String(this._idToken.claims[claim]) ?? null;
    }
    return null;
  }
  private checkClaim(claim: string): boolean {
    if (this._idToken) {
      // console.log("claims",this._idToken.claims);
      return !!this._idToken.claims[claim];
    }
    return false;
  }

  private errorText(code: string): string {
    switch (code) {
      case "auth/invalid-credential":
        return "Invalid username or password."
      case "auth/email-already-in-use":
        return "There is already a user account with that email."
      default:
        return `Unkown error: ${code}`;
    }
  }

  public async getIdToken(refresh: boolean = false) {
    if (this.user) {
      this._idToken = await this.user.getIdTokenResult(refresh);
      return this._idToken.token;
    }
    return null;
  }

  async logout() {
    await signOut(this.auth);
    await this.router.navigate(["/frontpage"]);
  }

  /**
   * Check whether the user has valid access to the specified service
   * @param service
   */
  hasAccess(service: "ccq" | "cyc" | "fcf" | "ffl" | undefined) {
    if (this.admin || this.editor) {
      return true;
    }
    if (!service) {
      return false;
    }
    return this.checkClaim(service);
  }

  /**
   * Return the current plan the user is on
   */
  public plan(): string | null {
    if (this._idToken) {
      // console.log("claims",this._idToken.claims);
      const service = <string>this._idToken.claims["service"];
      return UserService.serviceName(service);
    }
    return null;
  }
  public resetPwd(email: string) {
    return sendPasswordResetEmail(this.auth,email);
  }

  async refresh() {
    await this.getIdToken(true);
    console.log("claims",this._idToken?.claims)
  }
  public static serviceName(code: string) {
    switch (code) {
      case "tcspro":
        return "TCS Pro";
      case "tcslight":
        return "TCS Light";
      case "tcsenterprise":
        return "TCS Enterprise";
      case "tcsenterpriseplus":
        return "TCS Enterprise Plus";
      case "tcsmini":
        return "TCS Mini";
      case "fcf":
        return "Find Cars Faster";
      case "ccq":
        return "Calculate Cars Quicker";
      case "ffl":
        return "Forex For Less";
      case "cyc": // IE
      case "cyc-uk": // UK
        return "Check Your Car"
      case "cycent": // UK only
        return "CYC Enterprise"
      case "cycentplus": // UK only
        return "CYC Enterprise Plus"
      default:
        return code;
    }
  }

  /**
   * Return the date of the last bill, or null of none set
   */
  lastBill(): Date | null {
    if (this._idToken) {
      // console.log("claims",this._idToken.claims);
      const lastBill = <number>this._idToken.claims["lastBill"];
      if (lastBill) {
        return new Date(lastBill * 1000);
      }
    }
    return null;
  }
  nextBill(): Date | null {
    const lastBill = this.lastBill();
    if (lastBill) {
      lastBill?.setDate(lastBill.getDate() + 30);
    }
    return lastBill;
  }
}
