import {Inject, Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import Dexie from "dexie";
import {UserService} from "./user.service";
import {FormControl, FormGroup} from "@angular/forms";
export interface SortCriteria {
  active: string | undefined;
  direction: string | undefined;
}
export interface VRT {
  bca_id: number;
  registrationNumber: string;
  id: number,
  make: string,
  model: string,
  transmissionType: number,
  numberOfDoors: number,
  engineType: number,
  bodyType: number,
  engineCapacity: number,
  statCode: number,
  mileage: number,
  yearOfRegistration: number,
  euroStatus: number,
  versionData: string,
  vrtEuro: number,
  omsp_current: number,
  attemptedVrt_ts: string, // 2024-02-19 00:24:32
  astonbarclay_id: number | null,
  manheim_id: number | null,
  autotrader_id: number | null,
  priceGBP: number,
  priceEst: number,
  priceEurExVat: number,
  priceEstExVat: number,
  shippingEur: number,
  customsDuty: number,
  profit: number,
  exchange_rate: number,
  roi: number // -0.17
}

@Injectable({
  providedIn: "root",
})

export class FcfService extends Dexie {
  public total: number = 0;
  public makes: {[make: string]: {[model: string]: number}} = {};
  public bodyTypes: number[] = [];
  public loadingFacets: boolean = false;
  public maxPrice: number = 200000;
  public maxCost: number = 200000;
  public maxEngineSize: number = 5000;
  // public maxKms: number = 200000;
  public loading: boolean = false;
  public maxProfit: number = 10000;
  maxRoi: number = 200;
  maxYear: number = new Date().getFullYear();
  minYear: number = 2000;
  maxCo2: number = 400;


  constructor(private apiService: ApiService, @Inject(LOCAL_STORAGE) private storage: StorageService, private userService: UserService) {
    super("tcs");
    this.version(6).stores({
      fcf: "id, masked, make, model, transmissionType, engineType, engineCapacity, kms, yearOfRegistration, vrtEuro, vat, shippingEur, customsDuty, profit, roi,priceEurExVat,priceEstExVat,totalCosts,sellerType,wltpco2,numberOfDoors"
    });
  }

  /**
   * Initialise by retrieving the FCF data
   * Only do this if current data is older than `stale` (hours)
   * @param stale
   */
  public async init(stale: number = 12) {
    if (!this.userService.hasAccess("fcf")) {
      // User does not have access
      // Purge the data
      await this.table("fcf").clear();
      return;
    }
    // Check if we need to get data
    this.total = await this.getTotal();
    if (this.total > 0) {
      // console.log(`${this.total} entries`)
      try {
        const last_ts = new Date(this.storage.get("fcf_ts"));
        const now = new Date();
        if (last_ts.valueOf() + stale * 3600 * 1000 > now.valueOf()) {
          // console.log(`Data is not stale (${last_ts.toISOString()})`);
          await this.getFacets();
          await this.getMaxMin();
          return;
        }
      } catch (e) {
        // Could not check last ts
      }
    }
    this.loading = true;
    const response = await this.apiService.getVrtList()
      .catch(e =>  console.error(e))
    if (response?.success) {
      this.storage.set("fcf_ts", new Date());
      // Purge old data
      await this.table("fcf").clear();
      // Populate data
      for (let vrt of response?.data) {
        // console.log(vrt);
        try {
          await this.table("fcf").add(vrt);
        } catch (e) {
          // We can ignore the errors
          // console.error(e);
        }
      }
      await this.makeFacets();
      await this.getMaxMin();

    } else {
      console.error(response?.msg);
    }
    this.loading = false;
  }

  /**
   * Retrieves all records from the database using the specified service.
   *
   * @return {Array} An array of all records stored in the 'fcf' database.
   */

  public async get(pageSize: number, pageIndex: number, sortCriteria: SortCriteria | undefined, filters: {
    bodyTypes: number[],
    makes: string[],
    models: string[],
    kmsMin: number,
    kmsMax: number,
    engineSizeMin: number,
    engineSizeMax: number,
    engineTypes: [number[]],
    purchasePriceMin: number,
    purchasePriceMax: number,
    totalCostMin: number,
    totalCostMax: number,
    profitMin: number,
    profitMax: number,
    roiMin: number,
    roiMax: number,
    sellerTypes: string[],
    yearMin: number,
    yearMax: number,
    transmissionTypes: number[],
    numberOfDoors: number[],
    keyword: string
  }): Promise<VRT[]> {
    // console.log('filters',filters);
    const table = this.table("fcf");
    // Apply sorting
    let coll;
    if (sortCriteria?.active) {
      console.log('sortCriteria', sortCriteria);
      coll = table.orderBy(sortCriteria.active);
      if (sortCriteria?.direction == 'desc') {
        // Reverse order
        coll.reverse();
      }
    } else {
      coll = table.toCollection();
    }

    if (filters.makes?.length > 0) {
      // Filter on make
      coll.and(vehicle => filters.makes.includes(String(vehicle.make)));
    }
    if (filters.models?.length > 0) {
      // Filter on model
      coll.and(vehicle => filters.models.includes(String(vehicle.model)));
    }
    if (filters.engineSizeMin > 0 || filters.engineSizeMax < this.maxEngineSize) {
      coll.and(vehicle => vehicle.engineCapacity >= filters.engineSizeMin && vehicle.engineCapacity <= filters.engineSizeMax);
    }
    if (filters.kmsMin > 0) {
      coll.and(vehicle => vehicle.kms >= filters.kmsMin && vehicle.kms <= filters.kmsMax);
    }
    if (filters.engineTypes?.length > 0) {
      const engineTypes: number[] = [];
      for (let engineType of filters.engineTypes){
        for (let et of engineType)
        {
          engineTypes.push(et)
        }
      }
      coll.and(vehicle => engineTypes.includes(vehicle.engineType));
    }
    // if (filters.purchasePriceMin > 0 || filters.purchasePriceMax < this.maxPrice) {
    //   coll.and(vehicle => vehicle.priceEurExVat >= filters.purchasePriceMin && vehicle.priceEurExVat <= filters.purchasePriceMax);
    // }
    if (filters.totalCostMin > 0 || filters.totalCostMax < this.maxCost) {
       coll.and(vehicle => vehicle.totalCosts >= filters.totalCostMin && vehicle.totalCosts <= filters.totalCostMax);
    }
    if (filters.profitMin > 100 || filters.profitMax < this.maxProfit) {
      coll.and(vehicle => vehicle.profit >= filters.profitMin && vehicle.profit <= filters.profitMax);
    }
    if (filters.roiMin > 0 || filters.roiMax < this.maxRoi) {
      coll.and(vehicle => vehicle.roi >= filters.roiMin && vehicle.roi <= filters.roiMax);
    }
    if (filters.sellerTypes.length > 0) {
      coll.and(vehicle => filters.sellerTypes.includes(vehicle.sellerType));
    }
    if (filters.yearMin > this.minYear || filters.yearMax < new Date().getFullYear()) {
      coll.and(vehicle => vehicle.yearOfRegistration >= filters.yearMin && vehicle.yearOfRegistration <= filters.yearMax);
    }
    if (filters.transmissionTypes.length > 0) {
      coll.and(vehicle => filters.transmissionTypes.includes(vehicle.transmissionType));
    }
     if (filters.bodyTypes.length > 0) {
      coll.and(vehicle => filters.bodyTypes.includes(vehicle.bodyType));
    }
    if (filters.numberOfDoors.length > 0) {
      coll.and(vehicle => filters.numberOfDoors.includes(vehicle.numberOfDoors));
    }
    if (filters.keyword.length > 1) {

      coll.and(vehicle => {
        const model = String(vehicle.model).toLowerCase();
        const make = String(vehicle.make ?? '').toLowerCase();
        const versionData = String(vehicle.versionData ?? '').toLowerCase();
        const keyword = String(filters.keyword).toLowerCase();
        return make.includes(keyword) || model.includes(keyword) || versionData.includes(keyword)
      });
    }
    this.total = await coll.count();

    return coll
      .offset(pageSize * (pageIndex - 1))
      .limit(pageSize)
      .toArray();
  }

  /**
   * Get max value for relevant parameters
   * @private
   */
  private async getMaxMin() {
    // this.maxPrice = (await this.table("fcf").orderBy('priceEurExVat').reverse().limit(1).toArray())[0].priceEurExVat ?? 200000;
    this.maxCost = (await this.table("fcf").orderBy('totalCosts').reverse().limit(1).toArray())[0].totalCosts ?? 200000;
    this.maxProfit = (await this.table("fcf").orderBy('profit').reverse().limit(1).toArray())[0].profit ?? 200000;
    this.maxRoi = (await this.table("fcf").orderBy('roi').reverse().limit(1).toArray())[0].roi ?? 200;
    this.maxEngineSize = (await this.table("fcf").orderBy('engineCapacity').reverse().limit(1).toArray())[0].engineCapacity ?? 5000;
    this.minYear = (await this.table("fcf").orderBy('yearOfRegistration').limit(1).toArray())[0].yearOfRegistration ?? 2000;
    this.maxCo2 = (await this.table("fcf").orderBy('wltpco2').reverse().limit(1).toArray())[0].wltpco2 ?? 400;
    // this.maxKms = (await this.table("fcf").orderBy('kms').reverse().limit(1).toArray())[0].kms ?? 200000;
  }
  private async getFacets(): Promise<void> {
    this.loadingFacets = true;
    if (!this.loadFacets()) {
      await this.makeFacets();
    }
    this.loadingFacets = false;
  }

  private async makeFacets(): Promise<void> {
    this.makes = {};
    const bodyTypes: number[] = [];
    await this.table("fcf").each(vehicle => {
      bodyTypes.push(vehicle.bodyType);
      if (this.makes[vehicle.make] === undefined) {
        this.makes[vehicle.make] = {};
      }
      if (this.makes[vehicle.make][vehicle.model] === undefined) {
        this.makes[vehicle.make][vehicle.model] = 0;
      }
      this.makes[vehicle.make][vehicle.model]++;
    });
    this.bodyTypes = bodyTypes.filter(FcfService.uniqueValues);
    this.saveFacets();
  }
  private static uniqueValues(value: any, index: number, array: any[]) {
    return array.indexOf(value) === index;
  }
  /**
   * Retrieves the total count from the "fcf" table.
   *
   * @return {Promise<number>} A promise that resolves to the total count from the "fcf" table or 0 if an error occurs.
   */
  private async getTotal(): Promise<number> {
    const total = await this.table("fcf").count().catch(e => {});
    return total ?? 0;
  }

  private saveFacets(): void {
    this.storage.set('makes', this.makes);
    this.storage.set('bodyTypes', this.bodyTypes);
  }
  private loadFacets(): boolean {
    this.makes = this.storage.get('makes');
    this.bodyTypes = this.storage.get('bodyTypes');
    return Boolean(this.makes && this.bodyTypes);
  }
  initForm(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      makes: new FormControl([]),
      models: new FormControl({ value: [], disabled: true }),
      kmsMin: new FormControl(0),
      kmsMax: new FormControl(150000),
      engineSizeMin: new FormControl(0),
      engineSizeMax: new FormControl(this.maxEngineSize),
      engineTypes: new FormControl([]),
      purchasePriceMin: new FormControl(0),
      purchasePriceMax: new FormControl(this.maxPrice),
      totalCostMin: new FormControl(0),
      totalCostMax: new FormControl(this.maxCost),
      profitMin: new FormControl(100),
      profitMax: new FormControl(this.maxProfit),
      roiMin: new FormControl(0),
      roiMax: new FormControl(this.maxRoi),
      sellerTypes: new FormControl([]),
      yearMin: new FormControl(this.minYear),
      yearMax: new FormControl(this.maxYear),
      co2Min: new FormControl(0),
      co2Max: new FormControl(this.maxCo2),
      transmissionTypes: new FormControl([]),
      numberOfDoors: new FormControl([]),
      bodyTypes: new FormControl([]),
      keyword: new FormControl(""),
    });
  }
}
