import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatAnchor, MatButton} from "@angular/material/button";
import {CurrencyPipe, DatePipe, DecimalPipe, NgClass, NgOptimizedImage} from "@angular/common";
import {PricetagComponent} from "../../../components/pricetag/pricetag.component";
import {ProductsComponent} from "../../../components/products/products.component";
import {ReviewsComponent} from "../../../components/reviews/reviews.component";
import {RouterLink} from "@angular/router";
import {StatbitsComponent} from "../../../components/statbits/statbits.component";
import {VrmSearchComponent} from "../../../components/vrm-search/vrm-search.component";
import {MatFormField, MatInput, MatPrefix} from "@angular/material/input";
import {MatOption, MatSelect} from "@angular/material/select";
import {HeroBgService} from "../../../services/hero-bg.service";
import {CurrencySymbolPipe} from "../../../pipes/currency-symbol.pipe";
import {ReactiveFormsModule} from "@angular/forms";
import {FflContentComponent} from "../../../components/ffl-content/ffl-content.component";

@Component({
  selector: "app-ffl",
  standalone: true,
  imports: [
    MatButton,
    NgOptimizedImage,
    PricetagComponent,
    ProductsComponent,
    ReviewsComponent,
    RouterLink,
    StatbitsComponent,
    VrmSearchComponent,
    MatInput,
    MatFormField,
    MatSelect,
    MatOption,
    MatPrefix,
    CurrencyPipe,
    CurrencySymbolPipe,
    NgClass,
    DecimalPipe,
    DatePipe,
    MatAnchor,
    ReactiveFormsModule,
    FflContentComponent
  ],
  templateUrl: "./ffl.component.html",
  styleUrl: "./ffl.component.scss"
})
export class FflComponent implements OnInit, OnDestroy {
  constructor(private hero: HeroBgService) {
  }

  ngOnInit() {
    this.hero.setBg("/assets/img/bank_notes.jpg", "20% 20%",'unset',"120%",undefined, "brightness(0.45) grayscale(0.8)");
  }
  ngOnDestroy() {
    this.hero.reset();
  }


}
