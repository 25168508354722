<h3 mat-dialog-title>
  @if(data.mode === 'fcf') {
    Filter your table
  } @else {
    Choose filters for your notification
  }
</h3>
<mat-dialog-content [formGroup]="data.filtersFormGroup">
  <input type="hidden" formControlName="id">
  <mat-form-field>
    <mat-label>Seller types</mat-label>
    <mat-select formControlName="sellerTypes" multiple>
      <mat-option value="Private">Private</mat-option>
      <mat-option value="Trade">Traders</mat-option>
      <mat-option value="Other">Other</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Body types</mat-label>
    <mat-select formControlName="bodyTypes" multiple>
      @for (bodyType of fcfService.bodyTypes; track bodyType) {
        <mat-option [value]="bodyType">{{bodyType | bodyType}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Makes
      <mat-progress-spinner *ngIf="fcfService.loadingFacets" mode="indeterminate"
                            [diameter]="15"></mat-progress-spinner>
    </mat-label>
    <mat-select multiple formControlName="makes" #makesFilter [disabled]="fcfService.loadingFacets">
      @for (make of fcfService.makes | keyvalue; track make.key) {
        <mat-option [value]="make.key">{{ make.key }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Models
      <mat-progress-spinner *ngIf="fcfService.loadingFacets" mode="indeterminate"
                            [diameter]="15"></mat-progress-spinner>
    </mat-label>
    <mat-select multiple formControlName="models" #modelsFilter>
      @for (make of makesFilter.value; track make) {
        @for (model of fcfService.makes[make] | keyvalue; track model.key) {
          <mat-option [value]="model.key">{{ model.key }} ({{ model.value }})</mat-option>
        }
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Fuel types</mat-label>
    <mat-select formControlName="engineTypes" multiple>
      <mat-option [value]="[1]">Petrol</mat-option>
      <mat-option [value]="[2]">Diesel</mat-option>
      <mat-option [value]="[3]">Electric</mat-option>
      <mat-option [value]="[8,16]">Hybrid petrol</mat-option>
      <mat-option [value]="[10,15]">Hybrid diesel</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Transmission types</mat-label>
    <mat-select formControlName="transmissionTypes" multiple>
      <mat-option [value]="[1]">Manual</mat-option>
      <mat-option [value]="[2]">Automatic</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Doors</mat-label>
    <mat-select formControlName="numberOfDoors" multiple>
      @for (i of [2,3,4,5,6,7]; track i) {
        <mat-option [value]="i">{{i}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field class="double">
    <mat-label>Keyword search</mat-label>
    <input type="text" matInput formControlName="keyword">
  </mat-form-field>
  <div class="formField triple">
    <mat-label>Total cost to import</mat-label>
    <mat-slider min="0" [max]="fcfService.maxCost" step="500" discrete [displayWith]="formatPrice">
      <input matSliderStartThumb formControlName="totalCostMin">
      <input matSliderEndThumb formControlName="totalCostMax">
    </mat-slider>
  </div>
  <div class="formField triple">
    <mat-label>Estimated profits</mat-label>
    <mat-slider min="100" [max]="fcfService.maxProfit" step="100" discrete [displayWith]="formatPrice">
      <input matSliderStartThumb formControlName="profitMin">
      <input matSliderEndThumb formControlName="profitMax">
    </mat-slider>
  </div>
  <div class="formField triple">
    <mat-label>Return on investment</mat-label>
    <mat-slider min="0" [max]="fcfService.maxRoi" step="0.05" discrete [displayWith]="formatPercent">
      <input matSliderStartThumb formControlName="roiMin">
      <input matSliderEndThumb formControlName="roiMax">
    </mat-slider>
  </div>
  <div class="formField triple">
    <mat-label>Year</mat-label>
    <mat-slider [min]="fcfService.minYear" [max]="fcfService.maxYear" step="1" discrete>
      <input matSliderStartThumb formControlName="yearMin">
      <input matSliderEndThumb formControlName="yearMax">
    </mat-slider>
  </div>
  <div class="formField triple">
    <mat-label>CO<sub>2</sub>-emissions (g/km)</mat-label>
    <mat-slider min="0" [max]="fcfService.maxCo2" step="20" discrete [displayWith]="formatGPerKm">
      <input matSliderStartThumb formControlName="co2Min">
      <input matSliderEndThumb formControlName="co2Max">
    </mat-slider>
  </div>

  <div class="formField triple">
    <mat-label>Engine size</mat-label>
    <mat-slider min="0" [max]="fcfService.maxEngineSize" step="200" discrete [displayWith]="formatEngineSize">
      <input matSliderStartThumb formControlName="engineSizeMin">
      <input matSliderEndThumb formControlName="engineSizeMax">
    </mat-slider>
  </div>
  <div class="formField triple">
    <mat-label>Mileage</mat-label>
    <mat-slider min="0" [max]="150000" step="500" discrete [displayWith]="formatKms">
      <input matSliderStartThumb formControlName="kmsMin">
      <input matSliderEndThumb formControlName="kmsMax">
    </mat-slider>
  </div>


<!--  <div class="formField double" [hidden]="true">-->
<!--    <mat-label>Purchase price</mat-label>-->
<!--    <mat-slider min="0" [max]="fcfService.maxPrice" step="500" discrete [displayWith]="formatPrice">-->
<!--      <input matSliderStartThumb formControlName="purchasePriceMin">-->
<!--      <input matSliderEndThumb formControlName="purchasePriceMax">-->
<!--    </mat-slider>-->
<!--  </div>-->
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Close</button>
  @if (data.mode === 'fcf') {
    <button mat-raised-button (click)="resetFilters()" [disabled]="data.filtersFormGroup.untouched">Reset</button>
    <span class="spacer"></span>
    <button mat-raised-button (click)="createNotification()" [disabled]="data.filtersFormGroup.untouched" matTooltip="Receive automated e-mail notification about new vehicles matching the filters you've set."><mat-icon>add_alert</mat-icon> Create alert</button>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" (click)="applyFilters()" [disabled]="data.filtersFormGroup.untouched">Apply</button>
  } @else if (data.mode === 'notifications') {
    <span class="spacer"></span>
    <button mat-raised-button color="primary" (click)="createNotification()" [disabled]="data.filtersFormGroup.untouched">Save</button>
  }
</mat-dialog-actions>
