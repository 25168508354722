@if (!nav.hidden) {
  <nav>
    @if (userService.isLoggedIn() && !showExternal) {
      <div role="menuitem" routerLink="/home/ccq" [hidden]="!localeService.showService('ccq')">Calculate Cars Quicker</div>
      <div role="menuitem" routerLink="/home/cyc" [hidden]="!localeService.showService('cyc')">Check Your Car</div>
      <div role="menuitem" routerLink="/home/fcf" [hidden]="!localeService.showService('fcf')">Find Cars Faster</div>
      <div role="menuitem" routerLink="/ffl" [hidden]="!localeService.showService('ffl')">Forex For Less</div>
      <div role="menuitem" routerLink="/home/ctf" [hidden]="!localeService.showService('ctf')">Cars to Forecourt</div>
      <div role="menuitem" routerLink="/home/activity">Recent Activity</div>
      <div role="menuitem" routerLink="/home/admin" *ngIf="userService.admin">Admin</div>
    } @else {
      <div role="menuitem" routerLink="/ccq" [hidden]="!localeService.showService('ccq')">Calculate Cars Quicker</div>
      <div role="menuitem" routerLink="/cyc" [hidden]="!localeService.showService('cyc')">Check Your Car</div>
      <div role="menuitem" routerLink="/fcf" [hidden]="!localeService.showService('fcf')">Find Cars Faster</div>
      <div role="menuitem" routerLink="/tcspro" [hidden]="!localeService.showService('tcspro')">TheCarSite Pro</div>
      <div role="menuitem" routerLink="/ffl" [hidden]="!localeService.showService('ffl')">Forex For Less</div>
      <div role="menuitem" routerLink="/contact">Contact</div>
    }
  </nav>
}
