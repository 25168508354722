<section class="header">
  <h1>The Most Accurate and UpToDate Data.</h1>
  <h2>Superior Car History Checks Made Easy.</h2>
</section>
<section class="hero">
  <div class="search-container noprint">
    <div class="search">
      <app-vrm-search mode="cyc" #vrmSearch [disabled]="searching"></app-vrm-search>
      <mat-slide-toggle #financeCheck (change)="toggleFinance($event)" [checked]="getFinancePref()">Finance checks (+1 credit)</mat-slide-toggle>
      <mat-progress-spinner *ngIf="searching" diameter="30" mode="indeterminate"></mat-progress-spinner>
      <p *ngIf="searching && motivationalText">{{ motivationalText }}</p>
    </div>
  </div>
</section>
<section class="pageContent">
  <div class="search-container">
    <a id="cycStart"></a>
    @if (searchResult) {
      <div class="searchRes card">
        <h3>{{ searchResult['make'] }} {{ searchResult['model'] }}</h3>
        <div role="table" class="searchResTable">
          <ng-container *ngFor="let res of searchResult | formatRes | keyvalue">
            <ng-container *ngIf="res.value">
              <div role="rowheader" class="rowheader">{{ res.key }}</div>
              <div role="cell" class="value">{{ res.value }}</div>
            </ng-container>
          </ng-container>
        </div>
        <mat-progress-spinner *ngIf="detailsLoading" mode="indeterminate" diameter="50"></mat-progress-spinner>
      </div>

      <mat-accordion>
        @for (check of checks | keyvalue; track check.key) {
          @if (check.value[curLoc]) {
            <mat-expansion-panel [expanded]="false" [disabled]="check.value.loading">
              <mat-expansion-panel-header>
                <div class="expansion-header-content">
                  <h3>{{ check.value.title }}</h3>
                  @if (check.value.loading) {
                    <mat-progress-spinner mode="indeterminate" diameter="16"></mat-progress-spinner>
                  } @else {
                    <div class="trafficLight" [ngClass]="check.value.result?.status"></div>
                  }
                </div>
              </mat-expansion-panel-header>
              <div class="check-content">
                @for (txt of check.value.result?.text; track txt) {
                  <p>{{ txt }}</p>
                }
                @if (check.value.result?.tableData) {
                  <div role="table" class="searchResTable valsRight">
                    @for (res of check.value.result?.tableData | keyvalue: origOrder; track res.key) {
                      @if (res.value) {
                        <div role="rowheader" class="rowheader">{{ res.key }}</div>
                        <div role="cell" class="value">{{ res.value }}</div>
                      }
                    }
                  </div>
                } @else if (check.value.error) {
                  <p class="error">{{ check.value.error }}</p>
                }
              </div>
            </mat-expansion-panel>
          }
        }
      </mat-accordion>

    }
  </div>
  <app-disclaimer></app-disclaimer>
  <button class="bug" mat-raised-button color="primary" (click)="bug.report('Check Your Car', {vehicleData: searchResult, checks: checks})"><mat-icon>flag</mat-icon> Report an issue with this report</button>
</section>

@if (searchResult) {
  <app-ffl-content currency="gbp" [avgCars]="10" [avgPrice]="Number(this.searchResult?.['priceGBP'])"></app-ffl-content>
}
<section class="userActivity" [hidden]="userActivityComponent.activity.data.length === 0">
  <h2>Recent checks</h2>
  <div class="activity-container">
    <app-user-activity #userActivityComponent [entries]="10" endpoint="cyc"></app-user-activity>
  </div>
</section>
@if (searchResult) {
  <section class="hero">
    <div class="prods">
      <app-products [exclude]="'cyc'"></app-products>
    </div>
  </section>
}
