import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogModule, MatDialogTitle} from "@angular/material/dialog";
import {DecimalPipe, KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {formatRes} from "../../../../pipes/format-res.pipe";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {RouterLink} from "@angular/router";
import {ApiResponse, ApiService} from "../../../../services/api.service";
import {UserService} from "../../../../services/user.service";
import {EquivCarsDialog} from "../equiv-cars/equiv-cars";
import {VRT} from "../../../../services/fcf.service";

@Component({
  selector: "dialog-car-details",
  templateUrl: "../dialog-car-details/dialog-car-details.html",
  styleUrl: "../dialog-car-details/dialog-car-details.scss",
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, DecimalPipe, KeyValuePipe, NgForOf, NgIf, formatRes, MatButtonModule, MatDialogModule, NgClass, MatTooltipModule, MatSnackBarModule, MatProgressSpinner, RouterLink],
})
export class CarDetailsDialog {
  protected readonly Number = Number;
  protected loadingEquivs = false;

  constructor(private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) protected data: any, private apiService: ApiService, protected dialog: MatDialog, protected userService: UserService) {
  }

  getSource(element: VRT): { url: string, source: string } {
    let source = {
      url: "", source: ""
    };
    if (element.autotrader_id) {
      source.url = `https://www.autotrader.co.uk/car-details/${element.autotrader_id}`;
      source.source = "Autotrader.co.uk";
    } else if (element.astonbarclay_id) {
      source.url = `https://catalogue.astonbarclay.net/details/catalogue/${element.astonbarclay_id}`;
      source.source = "AstonBarclay.net";
    } else if (element.manheim_id) {
      source.url = `https://www.manheim.co.uk/search?keywords=${element.registrationNumber}`;
      source.source = "Manheim.co.uk";
    } else if (element.bca_id) {
      source.url = `https://www.bca.co.uk/lot/${element.registrationNumber}`;
      source.source = "BCA.co.uk";
    }
    return source;
  }

  async showEquivs(id: number) {
    this.loadingEquivs = true;
    const equivs = <ApiResponse>await this.apiService.getEquivs(id)
      .catch(e => console.error(e));
    this.loadingEquivs = false;
    if (equivs?.data) {
      this.dialog.open(EquivCarsDialog, {
        data: {
          data: this.data, equivs: equivs.data
        },
      });
    } else {
      this.snackBar.open("We could not find any equivalent vehicles currently on the Irish market", undefined, {
        panelClass: "snack-error", duration: 5000
      });
    }
  }

  async maskEntry(data: any) {
    await this.apiService.maskEntry(data.id);
    data.masked = data.masked ? null : "masked";
    this.dialog.closeAll();
  }
}
