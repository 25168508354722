import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {ViewportScroller} from "@angular/common";

@Component({
  selector: 'app-upgrade',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogTitle
  ],
  templateUrl: './upgrade.component.html',
  styleUrl: './upgrade.component.scss'
})
export class UpgradeComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { error: HttpErrorResponse}, private router: Router, protected userService: UserService, private dialog: MatDialog) {

  }

  upgrade() {
    // Get the current product and allow one higher
    let tcs = 'pro';
    switch (this.userService.getClaim('service')) {
      case 'tcsmini':
        tcs = 'light';
        break;
      case 'cyc':
      case 'tcslight':
        tcs = 'pro'
        break;
      case 'tcspro':
        tcs = 'enterprise';
        break;
      case 'tcsenterprise':
        tcs = 'enterpriseplus';
        break;
    }
    this.router.navigate(['/choose-product'], {queryParams: { tcs: tcs}});
    this.dialog.closeAll();
  }

  buyCredits() {
    this.router.navigate(['/home/buy-credits']);
    this.dialog.closeAll();
  }
}
