<section class="header">
  <h1>
    <span>Save Time.</span>
    <span>Cut Costs.</span>
    <span>Grow Profits.</span>
    <span>Sell Cars Faster.</span>
  </h1>
  <h2>TheCarSite is Your One Stop Shop.</h2>
  <button class="c2aLarge" mat-raised-button role="link" color="primary" routerLink="/register">Get Started</button>
</section>
<section class="hero">
  <div class="prods">
    <app-products [frontpage]="true"></app-products>
  </div>
</section>
<section class="guarantee">
  <div class="textContent">
    <h2>Our Guarantee is Simple</h2>
    <ol>
      <li>NO BS</li>
      <li>To Save You Time</li>
      <li>To Reduce Your Costs</li>
      <li>To Help You Avoid Scrap and Crap</li>
      <li>To Help You Find Cars with the Most Profit</li>
      <li>To Revolutionise The Way You Work</li>
      <li>To Be Better Than Every Platform You Use Put Together</li>
    </ol>
  </div>
  <div class="illustration"></div>
</section>
<section class="about">
  <div class="textContent">
    <h2>So Who is TheCarSite?</h2>
    <app-about></app-about>
    <button mat-raised-button color="primary" class="c2aLarge" role="link" routerLink="/register">Get started</button>
  </div>
  <div class="illustration"></div>
</section>
<section class="reviews">
  <h2>What Our Customers Just Like You Say</h2>
  <app-reviews></app-reviews>
</section>
<section class="productDetails">
  <h2>
    <span>One Partner. Every Solution.</span>
    <span>One Fair Fee. All in One Place.</span>
  </h2>
  <h3>We are TheCarSite for You.</h3>
  <app-prod-details [showPriceTag]="false"></app-prod-details>
</section>
<section class="stats">
  <h2>Why You Need TheCarSite</h2>
  <app-statbits></app-statbits>
  <button color="primary" class="c2aLarge" mat-raised-button routerLink="/register" role="link">Get Started</button>
</section>


