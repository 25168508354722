import {Routes} from '@angular/router';
import {ContactComponent} from "./pages/contact/contact.component";
import {AboutPageComponent} from "./pages/about/about.component";
import {QuotesComponent} from "./pages/quotes/quotes.component";
import {AdminComponent} from "./pages/admin/admin.component";
import {adminGuard} from "./guards/admin.guard";
import {NotificationsComponent} from "./components/notifications/notifications.component";
import {AuthGuard, redirectUnauthorizedTo} from "@angular/fire/auth-guard";
import {FrontpageComponent} from "./pages/frontpage/frontpage.component";
import {LandingComponent} from "./pages/home/landing.component";
import {CcqHomeComponent} from "./pages/home/ccq/ccq-home.component";
import {FcfHomeComponent} from "./pages/home/fcf/fcf-home/fcf-home.component";
import {CycHomeComponent} from "./pages/home/cyc/cyc-home.component";
import {paidGuard} from "./guards/paid.guard";
import {CcqComponent} from "./pages/products/ccq/ccq.component";
import {FcfComponent} from "./pages/products/fcf/fcf.component";
import {CycComponent} from "./pages/products/cyc/cyc.component";
import {FflComponent} from "./pages/products/ffl/ffl.component";
import {TcsproComponent} from "./pages/products/tcspro/tcspro.component";
import { LoginPageComponent} from "./pages/login/login.component";
import {RegisterComponent} from "./pages/register/register.component";
import {StartComponent} from "./pages/home/start/start.component";
import {AccountComponent} from "./pages/home/account/account.component";
import {CtfComponent} from "./pages/home/ctf/ctf.component";
import {ActivityComponent} from "./pages/home/activity/activity.component";
import {RegisterFormComponent} from "./pages/register-form/register-form.component";
import {RegisterDetailsComponent} from "./pages/register-details/register-details.component";
import {ChooseProductComponent} from "./pages/choose-product/choose-product.component";
import {SuccessComponent} from "./pages/payment/success/success.component";
import {LocaleSelectorComponent} from "./components/locale-selector/locale-selector.component";
import {localeGuard} from "./guards/locale.guard";
import {TermsComponent} from "./pages/terms/terms.component";
import {PrivacyComponent} from "./pages/privacy/privacy.component";
import {NotificationsHomeComponent} from "./pages/home/notifications-home/notifications-home.component";
import {BuyCreditsComponent} from "./pages/buy-credits/buy-credits";

export const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);
export const routes: Routes = [
  {
    path: 'home',
    component: LandingComponent,
    canActivate: [AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
    children: [
      { path: '', redirectTo: 'start', pathMatch: "full" },
      { path: 'start', component: StartComponent },
      { path: 'ccq', component: CcqHomeComponent, canActivate: [paidGuard], data: {service: 'ccq'}},
      { path: 'fcf', component: FcfHomeComponent, canActivate: [paidGuard], data: {service: 'fcf'}  },
      { path: 'cyc', component: CycHomeComponent, canActivate: [paidGuard], data: {service: 'cyc'} },
      { path: 'ctf', component: CtfComponent },
      { path: 'quotes', component: QuotesComponent },
      { path: 'account', component: AccountComponent },
      { path: 'buy-credits', component: BuyCreditsComponent },
      { path: 'activity', component: ActivityComponent },
      { path: 'notifications', component: NotificationsHomeComponent },
      { path: 'admin', component: AdminComponent, canActivate: [adminGuard]}
    ],
  },
  {path: 'frontpage', component: FrontpageComponent},
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutPageComponent },
  {path: 'ccq', component: CcqComponent},
  {path: 'fcf', component: FcfComponent},
  {path: 'cyc', component: CycComponent},
  {path: 'ffl', component: FflComponent},
  {path: 'tcspro', component: TcsproComponent},
  {path: 'login', component: LoginPageComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'register-form', component: RegisterFormComponent},
  {path: 'register-details', component: RegisterDetailsComponent, canActivate: [AuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin},},
  {path: 'choose-product', component: ChooseProductComponent, canActivate: [AuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin},},
  {path: 'payment/success', component: SuccessComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'privacy', component: PrivacyComponent},
  { path: 'locale', outlet: "overlay", component: LocaleSelectorComponent, canDeactivate: [localeGuard] },
  { path: '**', redirectTo: '/frontpage'}
];
