<section class="contact pageContent">
  <div class="prodCard">
    <h2>We're here to help you<br>get in touch any time.</h2>
    @if (!sent) {
      <mat-form-field appearance="outline">
        <mat-label>Write to us</mat-label>
        <textarea name="msg" [formControl]="msgControl" matInput placeholder="Drop us a line and we'll be in touch."></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Your e-mail</mat-label>
        <input [formControl]="emailControl" matInput type="email" name="email">
      </mat-form-field>
      <p class="error" *ngIf="contactError">{{contactError}}</p>
      <ngx-turnstile [siteKey]="'0x4AAAAAAAeTJ1vuEprBwZKB'" (resolved)="sendCaptchaResponse($event)" theme="light" [tabIndex]="0"></ngx-turnstile>
      <button mat-raised-button color="primary" [disabled]="msgControl.invalid || emailControl.invalid" (click)="submitForm()">Send</button>
    } @else {
      <p class="ok-msg">Thank you for your message. We will be in touch shortly.</p>
    }
    <ul>
      <!--        <li><img ngSrc="/assets/icons/smartphone-call.svg" width="16" height="16" alt="Phone"> <span>Call <a href="tel:+44123456789">+44 123456789</a></span></li>-->
      <li><img ngSrc="/assets/icons/mail.svg" width="16" height="16" alt="Phone"> <span>E-mail <a href="mailto:support@thecarsite.com">{{ 'support@thecarsite.com' }}</a></span></li>
      <li><img ngSrc="/assets/icons/comment.svg" width="16" height="16" alt="Chat"> <a role="button" class="chat clickable" onclick="$zoho.salesiq.floatwindow.visible('show')">Chat with us</a></li>
    </ul>
  </div>
</section>
