import { Component} from '@angular/core';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {ApiService} from "../../services/api.service";
import {MatOption, MatSelect} from "@angular/material/select";
import {DecimalPipe, NgClass} from "@angular/common";
import {Quote, QuotesService} from "../../services/quotes.service";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    MatProgressSpinner,
    MatTable,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatHeaderCellDef,
    MatSelect,
    MatOption,
    DecimalPipe,
    NgClass,
    MatButton
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent  {
  protected loadingUsers: boolean | undefined;
  protected loadingQuotes: boolean | undefined;
  protected users = new MatTableDataSource([]);
  protected quotes = new MatTableDataSource<Quote>([]);
  displayedColumns_users = ['lastLoginAt','email','role'];
  displayedColumns_quotes = ['id','ts','user','version','total','status'];
  showPanel: string | undefined;
  constructor(private apiService: ApiService, protected quoteService: QuotesService) {
  }
  async showUsers() {
    this.showPanel = 'users';
    this.loadingUsers = true;
    await this.loadUsers();

  }

  private async loadUsers() {
    this.users = new MatTableDataSource(await this.apiService.getUsers());
    this.loadingUsers = false;
  }

  getRole(element: any): string {
    if (element.customClaims['admin'] === true) {
      return 'admin';
    } else if (element.customClaims['editor'] === true) {
      return 'editor';
    }
    return '';
  }

  setRole($role: string, element: any) {
    this.apiService.assignRole($role, element.uid);
  }


  async showQuotes() {
    this.showPanel = 'quotes';
    this.loadingQuotes = true;
    await this.loadQuotes();
  }

  private async loadQuotes() {
    this.quotes = new MatTableDataSource<Quote>(await this.apiService.getQuotes())
    this.loadingQuotes = false;
  }


}
