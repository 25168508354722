<section class="header">
  <h1>The Most Accurate and UpToDate Data.</h1>
  <h2>Superior Car History Checks Made Easy.</h2>
</section>
<section class="hero">
  <app-prod-details singleProduct="cyc" [feature]="true" [showPriceTag]="true"></app-prod-details>
</section>
<section class="usps">
  <h2>Why You Should Use Check Your Car</h2>
  <div class="uspsContainer">
    <div class="usp">
      <img ngSrc="/assets/icons/money-waste.svg" height="50" width="50" alt="Waste money">
      <h3>Stop Wasting Money</h3>
      <div class="usp-content">
        <p>They Charge You How Much Per Car Report? What?</p>
        <p>Provenance checks are not a new thing. What’s new is accurate data and fair pricing.</p>
        <p>TheCarSite offers simple, clean, fair packages.</p>
        <p>It’s Your Business and Your Call. </p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/old.svg" height="50" width="50" alt="Old data">
      <h3>Old Data is Bad Data</h3>
      <div class="usp-content">
        <p>Most providers don’t update their data as often as you would think. One MAJOR player hasn’t updated it since
          Brexit started!</p>
        <p>With TheCarSite you get the most up to date data physically possible.</p>
        <p>Outdated Data or Our Data. You Pick.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/rating-stars.svg" height="50" width="50" alt="Experts">
      <h3>We Are Experts</h3>
      <div class="usp-content">
        <p>Handling big data is a highly specialised skillset. To collect, process, handle, disseminate and optimise –
          it takes expertise.</p>
        <p>TheCarSite has been building data orientated technology forever. We know what we are doing and more
          importantly what you want and need.</p>
        <p>Back Garden Mechanic or Automotive Surgeon. Your Call.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/dancing.svg" height="50" width="50" alt="Experts">
      <h3>Data Must Dance</h3>
      <div class="usp-content">
        <p>TheCarSite uses proprietary technology, custom IP and algorithms to find the not so ‘funny stuff’ hidden in
          the data. We spot trends to avoid the bends.</p>
        <p>TheCarSite finds the crap and scrap and makes them easy to see.</p>
        <p>Don’t buy crap or scrap ever again. Use TheCarSite.</p>
      </div>
    </div>
  </div>
  <button class="c2aLarge" mat-raised-button color="primary" role="link" routerLink="/register">Get Started</button>
</section>
<section class="reviews">
  <h2>What Our Customers Just Like You Say</h2>
  <app-reviews></app-reviews>
</section>
<section class="valueProp">
  <h2>What You Get From Check Your Car</h2>
  <div class="valueProps">
    <div>
      <img ngSrc="/assets/icons/thirty.svg" width="16" height="16" alt="30s">
      <p>Check your car in less than 30 seconds</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/real-time.svg" width="16" height="16" alt="Real time">
      <p>Data updated in real time</p></div>
    <div>
      <img ngSrc="/assets/icons/handshake.svg" width="16" height="16" alt="Fair price">
      <p>Fair Pricing</p></div>
    <div>
      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Magnifying glass">
      <p>Abnormalities Spotted</p></div>
    <div>
      <img ngSrc="/assets/icons/signing.svg" width="16" height="16" alt="Finance">
      <p>Finance Status</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/fingerprint-scan.svg" width="16" height="16" alt="Identity">
      <p>Vehicle Identity</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/stolen.svg" width="16" height="16" alt="Stolen car">
      <p>Stolen Check</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/broken.svg" width="16" height="16" alt="Scrap">
      <p>Write Off’s & Scrapped</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/frontal.svg" width="16" height="16" alt="Scrap">
      <p>Damage Checks</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/guarantee.svg" width="16" height="16" alt="Warranty">
      <p>Warranty Checks</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/maintenance.svg" width="16" height="16" alt="maintenance">
      <p>MOT History</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/velocity.svg" width="16" height="16" alt="Mileage">
      <p>Mileage History & Trends</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/people.svg" width="16" height="16" alt="Owners">
      <p>Previous Owner Data</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/license-plate.svg" width="16" height="16" alt="Number Plate">
      <p>Number Plate Data</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/efficacy.svg" width="16" height="16" alt="Running Costs">
      <p>Running Costs</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/car-tag.svg" width="16" height="16" alt="Price">
      <p>Expert Valuation</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/taxi.svg" width="16" height="16" alt="Taxi">
      <p>Taxi/Hackney</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/art.svg" width="16" height="16" alt="Colour">
      <p>Colour Change</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Affordable">
      <p>Highly Affordable</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/user-friendly.svg" width="16" height="16" alt="User friendly">
      <p>Easy to Use</p>
    </div>
  </div>
  <button class="c2aLarge" mat-raised-button role="link" routerLink="/register">Get Started</button>
</section>
<section class="stats">
  <h2>Why You Need Check Your Car</h2>
  <app-statbits sort="cyc"></app-statbits>
  <button class="c2aLarge" mat-raised-button role="link" color="primary" routerLink="/register">Get Started</button>
</section>
<section class="hero prods">
  <div class="prods">
    <app-products exclude="cyc"></app-products>
  </div>
</section>
