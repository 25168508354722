import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButton } from "@angular/material/button";
import {CurrencyPipe, NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import { Router, RouterLink } from "@angular/router";
import { VrmSearchComponent } from "../vrm-search/vrm-search.component";
import { PricetagComponent } from "../pricetag/pricetag.component";
import { UserService } from "../../services/user.service";
import {AccessDialogComponent} from "../dialogs/access-dialog/access-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {LocaleService} from "../../services/locale.service";
import {PriceCompactComponent} from "../price-compact/price-compact.component";

@Component({
  selector: "app-prod-details",
  standalone: true,
  imports: [
    MatButton,
    NgOptimizedImage,
    RouterLink,
    VrmSearchComponent,
    PricetagComponent,
    NgIf,
    NgClass,
    CurrencyPipe,
    PriceCompactComponent,
  ],
  templateUrl: "./prod-details.component.html",
  styleUrl: "./prod-details.component.scss",
})
export class ProdDetailsComponent {
  @Input("singleProduct") product: string | undefined;
  @Input() showPriceTag: boolean = true;
  @Input() feature: boolean = false;
  @Input() compact: boolean = false;
  @Output() load: EventEmitter<undefined> = new EventEmitter();
  constructor(
    private user: UserService,
    private router: Router,
    private dialog: MatDialog,
    protected localeService: LocaleService
  ) {}

  async gotoFCF() {
    if (this.user.isLoggedIn()) {
      await this.router.navigate(["/home/fcf"]);
    } else {
      this.dialog.open(AccessDialogComponent,{
        data: {
          feature: "Find Cars Faster"
        }
      });
    }
  }
}
