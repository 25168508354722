<h1>Admin</h1>
<div class="card adminNav">
  <button mat-raised-button [color]="showPanel == 'users' ? 'primary' : ''" (click)="showUsers()">Users</button>
  <button mat-raised-button [color]="showPanel == 'quotes' ? 'primary' : ''" (click)="showQuotes()">Quotes</button>
</div>
@if (showPanel == 'users') {
  <div class="card">
    <h3>Users</h3>
    @if (loadingUsers) {
      <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    } @else {
      <table mat-table [dataSource]="users">
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>E-mail</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>
        <ng-container matColumnDef="lastLoginAt">
          <th mat-header-cell *matHeaderCellDef>Last login</th>
          <td mat-cell *matCellDef="let element">{{ element.metadata.lastLoginAt?.date ?? 'Never' }}</td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Privilege</th>
          <td mat-cell *matCellDef="let element">
            <mat-select [value]="getRole(element)" (valueChange)="setRole($event, element)">
              <mat-option value="">None</mat-option>
              <mat-option value="editor">Editor</mat-option>
              <mat-option value="admin">Admin</mat-option>
            </mat-select>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns_users"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns_users;"></tr>
      </table>
    }
  </div>
} @else if (showPanel == 'quotes') {
  <div class="card">
    <h3>Quotes</h3>
    @if (loadingQuotes) {
      <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    } @else {
      <table mat-table [dataSource]="quotes">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="ts">
          <th mat-header-cell *matHeaderCellDef>Time</th>
          <td mat-cell *matCellDef="let element">{{ element.ts }}</td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let element">{{ element.user }}</td>
        </ng-container>
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef>Vehicle</th>
          <td mat-cell *matCellDef="let element">{{ element.make }} {{ element.model }} {{ element.version }}</td>
        </ng-container>
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let element" class="euro">{{ element.total | number : '1.2-2' : 'en' }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns_quotes"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns_quotes;" class="clickable"
            (click)="quoteService.openDialog(element)"></tr>
      </table>
    }
  </div>

}
