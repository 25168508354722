import { Component } from '@angular/core';
import {UserActivityComponent} from "../../../components/user-activity/user-activity.component";

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [
    UserActivityComponent
  ],
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.scss'
})
export class ActivityComponent {

}
