import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {NgOptimizedImage} from "@angular/common";
import {PricetagComponent} from "../../../components/pricetag/pricetag.component";
import {ProductsComponent} from "../../../components/products/products.component";
import {ReviewsComponent} from "../../../components/reviews/reviews.component";
import {RouterLink} from "@angular/router";
import {StatbitsComponent} from "../../../components/statbits/statbits.component";
import {VrmSearchComponent} from "../../../components/vrm-search/vrm-search.component";
import {HeroBgService} from "../../../services/hero-bg.service";
import {ProdDetailsComponent} from "../../../components/prod-details/prod-details.component";
import {CommerceService} from "../../../services/commerce.service";
import { Title} from "@angular/platform-browser";
import {PriceCompactComponent} from "../../../components/price-compact/price-compact.component";

@Component({
  selector: 'app-tcspro',
  standalone: true,
  imports: [
    MatButton,
    NgOptimizedImage,
    PricetagComponent,
    ProductsComponent,
    ReviewsComponent,
    RouterLink,
    StatbitsComponent,
    VrmSearchComponent,
    ProdDetailsComponent,
    PriceCompactComponent
  ],
  templateUrl: './tcspro.component.html',
  styleUrl: './tcspro.component.scss'
})
export class TcsproComponent implements OnInit, OnDestroy {
  protected price: string = '299';
  protected prodName: string = 'TCS Pro';
  constructor(private hero: HeroBgService, private commerce: CommerceService, private title: Title) {
    title.setTitle(this.prodName);
    commerce.gotProds.subscribe((products) => {
      this.init();
    })
  }
  ngOnInit() {
    this.init();
    this.hero.setBg('/assets/img/f1_flames_alt.jpg');
  }
  ngOnDestroy() {
    this.hero.reset();
  }

  private init() {
    if (this.commerce.defaultProduct) {
      this.prodName = this.commerce.defaultProduct.name;
      this.title.setTitle(this.prodName);
      this.price = String(this.commerce.defaultProduct.price / 100);
    }
  }
}
