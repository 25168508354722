<section class="header">
  <h1 *ngIf="localeService.locale == 'en-GB'">Check Your Car</h1>
  <h2>Choose the package that is right for you</h2>
  <ul class="productPreamble" *ngIf="localeService.locale == 'en-GB'">
    <li>Provenance checks are not a new thing.</li>
    <li>What’s new is accurate data and fair pricing.</li>
    <li>Superior Car History and Provenance Checks.</li>
    <li>Remember Old Data is Bad Data.</li>
    <li>Get the most up to date information for a fraction of the cost</li>
  </ul>
</section>
<section class="chooseProduct">

  <div class="registerForm">
    <div class="products">
      @if (commerce.products_sorted) {
        @for (prod of commerce.products_sorted; track prod.id) {
          <label [ngClass]="{default: prod.metadata['default'] == 1}">Best Value</label>
          <div class="prodCard" (click)="commerce.selectProd(prod)"
               [ngClass]="{selected: prod.checked, default: prod.metadata['default']}">

            <h2>{{ prod.name }}</h2>
            <h3 class="price">{{ localeService.currency() }} {{ (prod.price / 100) | number : '1.2-2' : 'en' }}</h3>
            <p class="descr">{{ prod.description }}</p>
            <ul>
              @for (feature of prod.marketing_features; track $index) {
                <li>{{ feature.name }}</li>
              }
            </ul>
            <mat-checkbox [checked]="prod.checked"></mat-checkbox>
          </div>
        }
      } @else {
        <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
      }
    </div>
    <!--    <div class="total">-->
    <!--      Total: &euro; {{total / 100 | number: '1.2-2': 'en'}} / month-->
    <!--    </div>-->
    <div class="actions">
      <button class="prev" mat-raised-button color="primary" role="link" routerLink="/register-details">Previous
      </button>
      <div class="middle trustedsite-trustmark" data-type="212" data-width="120" data-height="50"></div>
      <button class="next" mat-raised-button color="primary" [disabled]="!total || paymentInitiated"
              (click)="pay()">Pay {{ localeService.currency() }} {{ total / 100 | number: '1.2-2': 'en' }}
      </button>
    </div>
  </div>

  <ul class="productPreamble" *ngIf="localeService.locale == 'en-GB'">
    <li>All Credits Last for a Whopping 12 Months.</li>
    <li>Top Up's Start from £25 and Each Additional Credit is £2.50</li>
    <li>It's Better to Pick the Appropriate Plan and Save Even More Money Each and Every Month.</li>
    <li>Pricing is based on Direct Debit payment method – less for the banks and more for you and us!</li>
  </ul>
</section>
