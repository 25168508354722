<section class="header">
  <h1>
    <span>Stop Wasting Time Calculating VRT, VAT,</span>
    <span>Customs, NOx, Forex & Shipping.</span>
  </h1>
  <h2>Stop Wasting Money on Crap Solutions.</h2>
</section>

<section class="hero">
  <div class="search-container noprint">
    <app-vrm-search mode="ccq" #vrmSearch [disabled]="searching"></app-vrm-search>
  </div>
  @if (searching && motivationalText) {
    <h4 class="motivational-text">{{ motivationalText }}</h4>
  }
  <a id="ccqStart"></a>
  @if (vehicleData.vehicleDetails) {

    <div class="ccq-report-container">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <div class="expansion-header-content">
              <h3>
                @if (vehicleData.vehicleDetails) {
                {{vehicleData.vehicleDetails.make}} {{vehicleData.vehicleDetails.model_tfl ?? vehicleData.vehicleDetails.versionData}}
                } @else {
                  Vehicle Overview
                }
              </h3>
            </div>
          </mat-expansion-panel-header>
          <div class="table searchResTable" role="table">
            <div class="table-segment">
              <div class="row">
                <div class="property">Make</div>
                <div class="value">{{ vehicleData.vehicleDetails.make }}</div>
              </div>
              <div class="row">
                <div class="property">Model</div>
                <div class="value">{{ vehicleData.vehicleDetails.model }}</div>
              </div>
              <div class="row">
                <div class="property">Version</div>
                <div class="value">{{ vehicleData.vehicleDetails.model_tfl ?? vehicleData.vehicleDetails.versionData }}</div>
              </div>
              <div class="row">
                <div class="property">Transmission</div>
                <div class="value">{{ vehicleData.vehicleDetails.transmissionType | transmissionType }}</div>
              </div>
              <div class="row">
                <div class="property">Engine type</div>
                <div class="value">{{ vehicleData.vehicleDetails.engineType | engineType }}</div>
              </div>
              <div class="row">
                <div class="property">Engine size</div>
                <div class="value">{{ vehicleData.vehicleDetails.engineCapacity | number }} cc</div>
              </div>
              <div class="row">
                <div class="property">Body Type</div>
                <div class="value">{{ vehicleData.vehicleDetails.bodyType | bodyType }}</div>
              </div>
              <div class="row">
                <div class="property">Number of Doors</div>
                <div class="value">{{ vehicleData.vehicleDetails.numberOfDoors }}</div>
              </div>
              <div class="row">
                <div class="property">Colour</div>
                <div class="value">{{ vehicleData.vehicleDetails.colour }}</div>
              </div>
              <div class="row">
                <div class="property">Mileage</div>
                <div class="value">{{ vehicleData.vehicleDetails.mileage | number }} miles</div>
              </div>
              <div class="row">
                <div class="property">First Registered in Japan?</div>
                <div class="value">{{ vehicleData.vehicleDetails.firstRegisteredInJapan ? 'Yes' : 'No' }}</div>
              </div>
              <div class="row">
                <div class="property">EU Classification</div>
                <div class="value">{{ vehicleData.vehicleDetails.euClassification }}</div>
              </div>
            </div>
            <div class="table-segment">
              <div class="row">
                <div class="property">CO2 emissions</div>
                <div class="value">{{ vehicleData.vehicleDetails.co2Emissions }} g/km</div>
              </div>
              <div class="row">
                <div class="property">NOx emissions</div>
                <div class="value">{{ vehicleData.vehicleDetails.nox }} g/km</div>
              </div>
              <div class="row">
                <div class="property">Year of Manufacture</div>
                <div class="value">{{ vehicleData.vehicleDetails.yearOfManufacture }}</div>
              </div>
              <div class="row">
                <div class="property">Date of Registration</div>
                @if (vehicleData.vehicleDetails.monthOfFirstRegistration) {
                  <div class="value">{{ vehicleData.vehicleDetails.monthOfFirstRegistration | date }}</div>
                } @else {
                  <div class="value">{{ vehicleData.vehicleDetails.monthOfRegistration }}-{{ vehicleData.vehicleDetails.yearOfRegistration }}</div>
                }
              </div>
              <div class="row">
                <div class="property">License Plate</div>
                <div class="value">{{ vehicleData.vehicleDetails.registrationNumber }}</div>
              </div>
              <div class="row">
                <div class="property">MOT Status</div>
                <div class="value">{{ vehicleData.vehicleDetails.motStatus }}</div>
              </div>
              <div class="row">
                <div class="property">Tax Status</div>
                <div class="value">{{ vehicleData.vehicleDetails.taxStatus }}</div>
              </div>
               <div class="row">
                <div class="property">Tax Due</div>
                <div class="value">{{ vehicleData.vehicleDetails.taxDueDate }}</div>
              </div>
            <div class="row">
                <div class="property">Last V5C issued</div>
                <div class="value">{{ vehicleData.vehicleDetails.dateOfLastV5CIssued | date }}</div>
              </div>

            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- VRT SECTION -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <div class="expansion-header-content">
            <h3>Vehicle Registration Tax (VRT)</h3>
            <p *ngIf="vehicleData.vrtDetails?.vrtEuro && !vrtError" class="header-info">
              {{ vehicleData.vrtDetails?.vrtEuro | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
            </p>
          </div>
        </mat-expansion-panel-header>
        <div class="section">
          @if (vrtError) {
            <div class="report-error">
              <mat-icon aria-hidden="false" aria-label="error" fontIcon="error"></mat-icon>
              <p class="error-text">
                {{ vrtError }}
              </p>
            </div>
            @if (missingVariables.length > 0 && !vrtLoading) {
              <form
                [formGroup]="missingVarsForm"
                class="missing-vars-form"
                >
                <div class="form-container">
                  <mat-form-field *ngFor="let v of missingVariables">
                    <mat-label>{{ v | formatKey }}</mat-label>
                    <ng-template [ngIf]="missingOptions[v]" [ngIfElse]="numInput">
                      <mat-select
                        [formControlName]="v"
                        (selectionChange)="changeVrtMissingForm($event)">
                        <mat-option
                          *ngFor="let o of missingOptions[v] | keyvalue: valueAscOrder"
                          [value]="o.key"
                          >{{ o.value }}
                        </mat-option>
                      </mat-select>
                    </ng-template>
                    <ng-template #numInput>
                      <input
                        [formControlName]="v"
                        matInput
                        type="number"
                        step="1"
                        min="0"
                        [placeholder]="v | formatKey" />
                    </ng-template>
                  </mat-form-field>
                </div>
                <!-- <button mat-raised-button (click)="reset()">Reset</button> -->
                <button mat-raised-button (click)="submitVrt()">Submit</button>
              </form>
            } @else if (similar.data.length > 0) {
              <p class="disclaimerSimilar">
                We were not able to find an exact match for the vehicle in the ROS index. This can happen if this particular model has not been allocated a statistical code by ROS yet.
                <br>
                <br>
                Below is a list of imperfect matches, that may or may not be representative of the VRT due for the vehicle you are probing. If you think one of the matches below can be representative, you can select it to re-run the VRT calculation using the statistical code for that version.
              </p>
              <table mat-table [dataSource]="similar">
                <ng-container matColumnDef="statCode">
                  <th mat-header-cell *matHeaderCellDef>Stat code</th>
                  <td mat-cell *matCellDef="let element" class="strong">{{ element.statCode }}</td>
                </ng-container>
                <ng-container matColumnDef="model">
                  <th mat-header-cell *matHeaderCellDef>Model</th>
                  <td mat-cell *matCellDef="let element">{{ element.model }}</td>
                </ng-container>
                <ng-container matColumnDef="version">
                  <th mat-header-cell *matHeaderCellDef>Version</th>
                  <td mat-cell *matCellDef="let element">{{ element.version }}</td>
                </ng-container>
                <ng-container matColumnDef="transmissionType">
                  <th mat-header-cell *matHeaderCellDef>Transmission</th>
                  <td mat-cell *matCellDef="let element">{{ element.transmissionType | transmissionType}}</td>
                </ng-container>
                <ng-container matColumnDef="numberOfDoors">
                  <th mat-header-cell *matHeaderCellDef>Doors</th>
                  <td mat-cell *matCellDef="let element">{{ element.numberOfDoors }}</td>
                </ng-container>
                <ng-container matColumnDef="engineType">
                  <th mat-header-cell *matHeaderCellDef>Engine type</th>
                  <td mat-cell *matCellDef="let element">{{ element.engineType | engineType }}</td>
                </ng-container>
                <ng-container matColumnDef="engineCapacity">
                  <th mat-header-cell *matHeaderCellDef>Engine capacity</th>
                  <td mat-cell *matCellDef="let element">{{ element.engineCapacity }}</td>
                </ng-container>
                <ng-container matColumnDef="bodyType">
                  <th mat-header-cell *matHeaderCellDef>Body</th>
                  <td mat-cell *matCellDef="let element">{{ element.bodyType | bodyType }}</td>
                </ng-container>
                <ng-container matColumnDef="euClassification">
                  <th mat-header-cell *matHeaderCellDef>EU Class</th>
                  <td mat-cell *matCellDef="let element">{{ element.euClassification }}</td>
                </ng-container>
                <ng-container matColumnDef="wltpco2">
                  <th mat-header-cell *matHeaderCellDef>CO<sub>2</sub></th>
                  <td mat-cell *matCellDef="let element">{{ element.wltpco2 }} g/km</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="selectStatCode(element.statCode)" class="clickable"
                    [ngClass]="{masked: element.masked}"></tr>
              </table>
            }
          } @else if (vehicleData.vrtDetails) {
            <img
              ngSrc="/assets/icons/vrt.svg"
              width="100"
              height="100"
              class="section-img"
              alt="VRT" />
            <p class="emphasized">
              {{ vehicleData.vrtDetails.vrtEuro | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
            </p>
            <p class="section-text">This is the calculated VRT amount for your vehicle import
              @if (anyFootnote) {
                *
              }.</p>
            @if (evExempt) {
              <!-- This is an EV within the exemptions-->
              <p class="section-footnote">* As this is an Electric Vehicle registered before 31st Dec 2025 it is eligible for VRT relief up to a maximum of € 5,000 dependent on the OMSP of the vehicle. <a (click)="evFootnote.hidden = false">Click here to read more</a>.</p>
              <div class="prodCard footnote" #evFootnote [hidden]="true">
                <h4>VRT relief for EVs</h4>
                <p>As per Revenue.ie series production passenger cars or commercial vehicles (VRT categories A and B) that are:</p>
                <ul>
                  <li>powered only by an electric motor</li>
                  <li>registered before 31 December 2025</li>
                </ul>
                <p>are eligible for Relief from VRT up to a maximum amount of € 5,000.</p>
                <p>Vehicles with an Open Market Selling Price (OMSP) of up to € 40,000 will be granted relief of up to € 5,000. Vehicles with an OMSP of greater than € 40,000 but less than € 50,000 will receive a reduced level of relief. Reliefs have been removed for any electric vehicles above € 50,000.</p>
              </div>
            }
            @if (showVrtDetails) {
              <div class="table searchResTable" role="table">
                <div class="table-segment">
                    <div class="row">
                      <div class="property">Make</div>
                      <div class="value">{{ vehicleData.vrtDetails.make }}</div>
                    </div>
                  <div class="row">
                      <div class="property">Model</div>
                      <div class="value">{{ vehicleData.vrtDetails.model }}</div>
                    </div>
                  <div class="row">
                      <div class="property">ROS Version designation</div>
                      <div class="value">{{ vehicleData.vrtDetails.version }}</div>
                    </div>
                  <div class="row">
                      <div class="property">Transmission</div>
                      <div class="value">{{ vehicleData.vrtDetails.transmissionType | transmissionType }}</div>
                    </div>
                   <div class="row">
                      <div class="property">Engine type</div>
                      <div class="value">{{ vehicleData.vrtDetails.engineType | engineType }}</div>
                    </div>
                  <div class="row">
                      <div class="property">Engine size</div>
                      <div class="value">{{ vehicleData.vrtDetails.engineCapacity | number }} cc</div>
                    </div>
                  <div class="row">
                      <div class="property">Body Type</div>
                      <div class="value">{{ vehicleData.vrtDetails.bodyType | bodyType }}</div>
                    </div>
                  <div class="row">
                      <div class="property">Number of Doors</div>
                      <div class="value">{{ vehicleData.vrtDetails.numberOfDoors }}</div>
                    </div>
                   <div class="row">
                      <div class="property">Colour</div>
                      <div class="value">{{ vehicleData.vehicleDetails.colour }}</div>
                    </div>
                  <div class="row">
                      <div class="property">Mileage</div>
                      <div class="value">{{ vehicleData.vrtDetails.mileage | number }} miles</div>
                    </div>
                  <div class="row">
                      <div class="property">First Registered in Japan?</div>
                      <div class="value">{{ vehicleData.vrtDetails.firstRegisteredInJapan ? 'Yes' : 'No' }}</div>
                    </div>
                  <div class="row">
                      <div class="property">EU Classification</div>
                      <div class="value">{{ vehicleData.vrtDetails.euClassification }}</div>
                    </div>
                </div>
                <div class="table-segment">
                  <div class="row">
                    <div class="property">CO2 emissions</div>
                      <div class="value">{{ vehicleData.vrtDetails.wltpco2 }} g/km</div>
                    </div>
                  <div class="row">
                      <div class="property">NOx emissions</div>
                      <div class="value">{{ vehicleData.vrtDetails.nox }} g/km</div>
                    </div>
                  <div class="row">
                      <div class="property">VRT Statistical code</div>
                      <div class="value">{{ vehicleData.vrtDetails.statCode }} g/km</div>
                    </div>


                  <div class="row">
                      <div class="property">Original OMSP</div>
                      <div class="value">{{ vehicleData.vrtDetails.omsp | currency: 'EUR' : 'symbol': '1.0-0' }}</div>
                    </div>
                  <div class="row">
                      <div class="property">Current OMSP</div>
                      <div class="value">{{ vehicleData.vrtDetails.omsp | currency: 'EUR' : 'symbol': '1.0-0' }}</div>
                    </div>
                   <div class="row">
                      <div class="property">OMSP adjustment for month</div>
                      <div class="value">{{ 1 - vehicleData.vrtDetails.monthlyAdjustment | percent: '1.0-0'}}</div>
                    </div>
                  <div class="row">
                      <div class="property">OMSP mileage reduction</div>
                      <div class="value">{{ vehicleData.vrtDetails.mileageRed | currency: 'EUR' : 'symbol': '1.0-0' }} </div>
                    </div>
                   <div class="row">
                      <div class="property">Depreciation code</div>
                      <div class="value">{{ vehicleData.vrtDetails.deprCode }} </div>
                    </div>
                  <div class="row">
                      <div class="property">Depreciation rate</div>
                      <div class="value">{{ vehicleData.vrtDetails.deprRate | percent: '1.1-1' }}</div>
                    </div>
                  <div class="row">
                    <div class="property">CO2 charge rate</div>
                    <div class="value">{{ vehicleData.vrtDetails.unmodifiedVRTAmount  | percent: '1.1-1' }}</div>
                  </div>
                  <div class="row">
                    <div class="property">CO2 charge</div>
                    <div class="value">{{ vehicleData.vrtDetails.co2Charges | currency: 'EUR' : 'symbol': '1.0-0' }} </div>
                  </div>
                  <div class="row">
                    <div class="property">NOx charge</div>
                    <div class="value">{{ vehicleData.vrtDetails.noxCharge | currency: 'EUR' : 'symbol': '1.0-0' }}</div>
                  </div>
                  <div class="row">
                    <div class="property">Total VRT charge</div>
                    <div class="value">{{ vehicleData.vrtDetails.vrtEuro | currency: 'EUR' : 'symbol': '1.0-0' }}</div>
                  </div>
                </div>
              </div>
            } @else {
              <button (click)="showVrtDetails = true" mat-raised-button color="primary">
                Show details
              </button>
            }
          } @else {
            <p class="motivational-text">Attempting to calculate the VRT.</p>
            <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
          }
        </div>
      </mat-expansion-panel>
      <!-- VAT SECTION -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <div class="expansion-header-content">
            <h3>Value-Added Tax (VAT)</h3>
            <p *ngIf="vehicleData.vehicleDetails.priceGBP" class="header-info">
              &euro; {{ vehicleData.vatDetails?.vat | number: "1.0-0" : "en" }}
            </p>
          </div>
        </mat-expansion-panel-header>
        <div class="section">
          <p class="note">
            Note: Vehicles brought into the State from the UK are subject to Value-Added Tax (VAT).
          </p>
          @if (vatError) {
            <div class="report-error">
              <mat-icon aria-hidden="false" aria-label="error" fontIcon="error"></mat-icon>
              <p class="error-text">
                {{ vatError }}
              </p>
            </div>
            @if (!vehicleData.vehicleDetails.priceGBP) {
              <ng-container *ngTemplateOutlet="priceForm"></ng-container>
            }
          } @else if (vehicleData.vatDetails) {
            <img
              ngSrc="/assets/icons/vat.svg"
              width="124"
              height="124"
              class="section-img"
              alt="Vat" />
            <p class="emphasized">
              &euro; {{ vehicleData.vatDetails.vat | number: "1.0-0" : "en" }}
            </p>
            <p class="section-text">This is the calculated VAT amount for your vehicle import.</p>
          } @else {
            <p class="motivational-text">Attempting to calculate the VAT.</p>
            <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
          }
        </div>
      </mat-expansion-panel>
      <!-- CUSTOMS DUTY SECTION -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <div class="expansion-header-content">
            <h3>Customs Duty</h3>
            <p *ngIf="vehicleData.customsDetails?.cost" class="header-info">
              &euro;
              {{ vehicleData.customsDetails?.cost | number: "1.0-0" : "en" }}
            </p>
          </div>
        </mat-expansion-panel-header>
        <div class="section">
          <p class="note">
            Note: Vehicles brought into the State from the UK may be subject to customs duty.
          </p>
          @if (customsError) {
            <div class="report-error">
              <mat-icon aria-hidden="false" aria-label="error" fontIcon="error"></mat-icon>
              <p class="error-text">
                {{ customsError }}
              </p>
            </div>
            @if (!vehicleData.vehicleDetails.priceGBP) {
              <ng-container *ngTemplateOutlet="priceForm"></ng-container>
            }
          } @else if (vehicleData.customsDetails) {
            <img
              ngSrc="/assets/icons/customs.svg"
              width="124"
              height="124"
              class="section-img customs-section-img"
              alt="Customs" />
            <p class="emphasized">
              &euro; {{ vehicleData.customsDetails.cost | number: "1.0-0" : "en" }}
            </p>
            <p class="section-text">
              This is the calculated Customs Duty at a Rate of
              {{ vehicleData.customsDetails.rate | percent: "1.0-0" }}
            </p>
          } @else {
            <p class="motivational-text">Attempting to calculate the Customs Duty.</p>
            <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
          }
        </div>
      </mat-expansion-panel>
      <!-- TRANSPORT SECTION -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <div class="expansion-header-content">
            <h3>Transport Costs</h3>
            <p *ngIf="vehicleData?.transportDetails?.cost" class="header-info">
              {{ vehicleData.transportDetails?.cost | currency: "EUR":"symbol": "1.0-0" : "en" }}
            </p>
          </div>
        </mat-expansion-panel-header>
        <div class="section">
          @if (!vehicleData.transportDetails) {
            <p class="section-text">
              Please enter the post codes for an estimate on transportation costs.
            </p>
            <form class="transport-form" [formGroup]="transportFormGroup" (submit)="getTransport()">
              <mat-form-field>
                <mat-label>Vehicle location (UK post code):</mat-label>
                <input matInput type="text" formControlName="uk" />
                <mat-error>Please enter a valid UK post code</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Destination (Irish post code):</mat-label>
                <input matInput type="text" formControlName="ie" />
                <mat-error>Please enter a valid IE post code</mat-error>
              </mat-form-field>
              <br />
              <button mat-raised-button type="submit">Submit</button>
            </form>
          } @else {
            <p class="note">Please note that shipping costs are estimates and may vary.</p>
            <div class="from-to">
              <p class="address from-address" [matTooltip]="vehicleData.transportDetails.origin">
                {{ vehicleData.transportDetails.origin }}
              </p>
              <img
                ngSrc="/assets/icons/car-trailer.svg"
                width="240"
                height="240"
                class="section-img"
                alt="shipping" />
              <p class="address to-address" [matTooltip]="vehicleData.transportDetails.destination">
                {{ vehicleData.transportDetails.destination }}
              </p>
              <p class="distance" *ngIf="vehicleData.transportDetails.distance">
                {{ vehicleData.transportDetails.distance | number: "1.0-0" : "en" }} miles
              </p>
            </div>
            <p class="emphasized">
              {{ vehicleData.transportDetails.cost | currency: "EUR":"symbol":"1.0-0" : "en" }}
            </p>
            <p class="section-text">
              This is the estimated shipping cost to transport this vehicle from
              <b>{{ vehicleData.transportDetails.origin }}</b> to
              <b>{{ vehicleData.transportDetails.destination }}</b>
            </p>
          }
          <mat-progress-spinner
            *ngIf="postCode && postCodeLoading"
            mode="indeterminate"
            diameter="50"></mat-progress-spinner>
        </div>
      </mat-expansion-panel>
      <!-- TOTAL SECTION -->
      @if (total) {
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <div class="expansion-header-content">
              <h3>Total Estimated Costs</h3>
              <p class="header-info">&euro; {{ total | number: "1.0-0" : "en" }}</p>
            </div>
          </mat-expansion-panel-header>
          <div class="section total-section">
            <h3>Costs Breakdown</h3>

            <div class="total-table">
              <div class="table single">
                <div class="table-segment">
                  @for (key of objectKeys(totalBreakdown); track key) {
                    @if(totalBreakdown?.[key] !== undefined) {
                      <div class="row">
                        <div class="property">{{ totalSchema[key] }}</div>
                        <div class="value">
                          {{ totalBreakdown?.[key] | currency: "EUR":"symbol":"1.0-0" : "en" }}
                        </div>
                      </div>
                    }
                  }
                </div>
              </div>
            </div>
            <p class="note">
              Currency conversion rate EUR/GBP
              {{ currency.rates["gbp"] | number: "1.3-3" : "en" }}
            </p>
            <div class="buttonRow">
              <button mat-raised-button color="primary" (click)="getQuote()">Get quote</button>
              <button mat-raised-button color="primary" (click)="bug.report('Calculate Cars Quicker', vehicleData)"><mat-icon>flag</mat-icon> Report an issue with this report</button>
  <!--            TODO add email me this report button -->
            </div>
          </div>
        </mat-expansion-panel>
      }
      <app-disclaimer></app-disclaimer>

      <ng-template #priceForm>
        <form [formGroup]="missingPriceForm" class="missing-price-form">
          <mat-form-field>
            <mat-label>Price (GBP)</mat-label>
            <input matInput type="number" formControlName="priceGBP" />
          </mat-form-field>
          <button mat-raised-button (click)="submitPrice()">Submit value</button>
        </form>
      </ng-template>
    </div>
  }

</section>
@if (this.vehicleData.vehicleDetails?.priceGBP) {
  <app-ffl-content currency="gbp" [avgCars]="10" [avgPrice]="this.vehicleData.vehicleDetails?.priceGBP"></app-ffl-content>
}
<section class="userActivity" [hidden]="userActivityComponent.activity.data.length === 0">
  <h2>Recent CCQ calculations</h2>
  <div class="activity-container">
    <app-user-activity #userActivityComponent [entries]="10" endpoint="ccq"></app-user-activity>
  </div>
</section>
@if (this.vehicleData.vehicleDetails) {
  <section class="hero">
    <div class="prods">
      <app-products [exclude]="'ccq'"></app-products>
    </div>
  </section>
}
