<section class="header">
  <h1>We Help You Find Your Ideal Next Purchase</h1>
  <h2>We Got You.</h2>
</section>
<section class="pageContent">
  <!--  @if (!init) {-->
  <!--    <app-prod-details singleProduct="fcf" [showPriceTag]="false" (load)="load()"></app-prod-details>-->
  <!--  } @else {-->

  <h3>List of vehicles currently on the market</h3>


  <mat-toolbar>
    <mat-toolbar-row>
      <span class="spacer"></span>
      <mat-progress-spinner mode="indeterminate" matTooltip="The vehicles are still loading into the table" diameter="25" *ngIf="fcfService.loading"></mat-progress-spinner>
      <mat-icon matTooltip="Toggle filters" (click)="openFilterDialog()">tune</mat-icon>

      <mat-paginator [pageSize]="20" [pageSizeOptions]="[20,50,100,250,500]" [length]="total" aria-label="Select page"
                     (page)="handlePageEvent($event)" showFirstLastButtons [class.disabled]="loading">
      </mat-paginator>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-progress-spinner *ngIf="loading" class="table-spinner" mode="indeterminate" [diameter]="50"></mat-progress-spinner>
  <table mat-table [dataSource]="vrt" matSort (matSortChange)="announceSortChange($event)" [class.disabled]="loading">
    <ng-container matColumnDef="make">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Make and model</th>
      <td mat-cell *matCellDef="let element">{{ element.make }} {{ element.model }}</td>
    </ng-container>
    <ng-container matColumnDef="yearOfRegistration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Year</th>
      <td mat-cell *matCellDef="let element">{{ element.yearOfRegistration }}</td>
    </ng-container>
    <ng-container matColumnDef="mileage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Mileage</th>
      <td mat-cell *matCellDef="let element">{{ element.mileage | milesToKm | number }} km</td>
    </ng-container>
    <ng-container matColumnDef="engineType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Engine type</th>
      <td mat-cell *matCellDef="let element">{{ (element | formatRes)['Engine type / fuel'] }}</td>
    </ng-container>
    <ng-container matColumnDef="priceEurExVat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Est. purchase price UK<sup>*</sup></th>
      <td mat-cell *matCellDef="let element" class="euro">{{ element.priceEurExVat | number : '1.0-0' : 'en' }}</td>
    </ng-container>
    <ng-container matColumnDef="priceEstExVat">
      <th mat-header-cell *matHeaderCellDef
          matTooltip="Based on currently listed vehicles with same specs and mileage" mat-sort-header>Est. sales price
        IE<sup>**</sup></th>
      <td mat-cell *matCellDef="let element" class="euro">{{ element.priceEstExVat | number: '1.0-0' : 'en' }}</td>
    </ng-container>
    <ng-container matColumnDef="vrtEuro">
      <th mat-header-cell *matHeaderCellDef matTooltip="Vehicle registration tax" mat-sort-header>VRT</th>
      <td mat-cell *matCellDef="let element" class="euro">{{ element.vrtEuro | number: '1.0-0' : 'en' }}</td>
    </ng-container>
    <ng-container matColumnDef="vat">
      <th mat-header-cell *matHeaderCellDef matTooltip="Value-added Tax, only for new vehicles" mat-sort-header>VAT
      </th>
      <td mat-cell *matCellDef="let element" class="euro">{{ element.vat | number: '1.0-0' : 'en' }}</td>
    </ng-container>
    <ng-container matColumnDef="customsDuty">
      <th mat-header-cell *matHeaderCellDef matTooltip="Customs duty payable on import" mat-sort-header>Customs duty
      </th>
      <td mat-cell *matCellDef="let element" class="euro">{{ element.customsDuty | number: '1.0-0' : 'en' }}</td>
    </ng-container>
    <ng-container matColumnDef="shippingEur">
      <th mat-header-cell *matHeaderCellDef
          matTooltip="Est. shipping to Ireland. Enter your postcode for a more accurate estimate." mat-sort-header>
        Est. shipping<sup>***</sup></th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'euro': element.shippingEur > 0}">{{ element.shippingEur > 0 ? (element.shippingEur | number: '1.0-0' : 'en') : 'n/a' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="profit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Est. profit</th>
      <td mat-cell *matCellDef="let element" class="euro">{{ element.profit | number: '1.0-0' : 'en' }}</td>
    </ng-container>
    <ng-container matColumnDef="roi">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ROI</th>
      <td mat-cell *matCellDef="let element">{{ element.roi * 100 | number: '1.0-0' : 'en' }} %</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="openDialog(element)" class="clickable"
        [ngClass]="{masked: element.masked}"></tr>
  </table>
  <!--          <mat-paginator [pageSize]="20" [pageSizeOptions]="[20,50,100,250,500]" aria-label="Select page" (page)="handlePageEvent($event)" [length]="fcfService.total" showFirstLastButtons [disabled]="loading"></mat-paginator>-->



  <p class="currencyAsterisk"><sup>*</sup> Estimate based on similar models with similar mileage. Ex. VAT. Currency
    conversion rate EUR/GBP {{ currency.rates["gbp"] | number: '1.3-3': 'en' }}<br>
    <sup>**</sup> Based on currently listed vehicles with same specs and mileage. Ex VAT.<br>
    <sup>***</sup> Est. transport costs to Dublin. Enter your postcode for a more accurate estimate.<br>
  </p>

  <p class="error" *ngIf="errorMsg">{{ errorMsg }}</p>
  <app-disclaimer></app-disclaimer>
  <!--  }-->
</section>
<!--<section class="valueProp">-->
<!--  <h2>What You Get From Find Cars Faster</h2>-->
<!--  <div class="valueProps">-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/relax.svg" width="16" height="16" alt="Time">-->
<!--      <p>Time. Time. Time. Time.</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/customer-service.svg" width="16" height="16" alt="Ideal cars">-->
<!--      <p>The Ideal Cars presented to You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Money">-->
<!--      <p>Projected Profit</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div class="img">ROI</div>-->
<!--      <p>Projected ROI</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Money">-->
<!--      <p>Total Cost to Land</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/stopwatch.svg" width="16" height="16" alt="Stopwatch">-->
<!--      <p>Average Time to Sell</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Magnifying glass">-->
<!--      <p>‘Funny Stuff’ Flagged (Before History Checks)</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div class="img">VRT</div>-->
<!--      <p>VRT Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div class="img">VAT</div>-->
<!--      <p>VAT Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div class="img">NOx</div>-->
<!--      <p>NOx Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/currencies.svg" width="16" height="16" alt="Currency">-->
<!--      <p>Forex Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/ship.svg" width="16" height="16" alt="Ship">-->
<!--      <p>Shipping Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/cars.svg" width="16" height="16" alt="Cars">-->
<!--      <p>Browse cars using dealer specific functionality</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Search">-->
<!--      <p>Search based on your criteria</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/alert.svg" width="16" height="16" alt="Alert">-->
<!--      <p>Alerts sent for your ideal cars</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/handshake.svg" width="16" height="16" alt="Alert">-->
<!--      <p>Importing Cars Made Easy</p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <button class="c2aLarge" mat-raised-button role="link" (click)="getStarted()">Get Started</button>-->
<!--</section>-->
<!--<section class="stats">-->
<!--  <h2>Why You Need TheCarSite</h2>-->
<!--  <app-statbits sort="fcf"></app-statbits>-->
<!--  <button class="c2aLarge" mat-raised-button role="link" color="primary" (click)="getStarted()">Get Started</button>-->
<!--</section>-->
