import { Injectable } from '@angular/core';
import {EventType, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(router: Router, private title: Title) {
    router.events.subscribe((event) => {
      if (event.type === EventType.NavigationEnd) {
        // Update the title
        let newTitle = 'TheCarSite.com';
        // console.log(event.url);
        switch (event.url) {
          case '/register-details':
          case '/register-form':
          case '/register':
            newTitle = 'Register';
            break;
           case '/login':
            newTitle = 'Login';
            break;
          case '/home/account':
            newTitle = 'Account';
            break;
          case '/home/activity':
            newTitle = 'Recent activity';
            break;
          case '/home/ccq':
          case '/ccq':
            newTitle = 'Calculate Cars Quicker';
            break;
          case '/home/cyc':
          case '/cyc':
            newTitle = 'Check Your Car';
            break;
          case '/ffl':
          case '/home/ffl':
            newTitle = 'Forex For Less';
            break;
          case '/home/fcf':
          case '/fcf':
            newTitle = 'Find Cars Faster';
            break;
          case '/home/ctf':
            newTitle = 'Cars to Forecourt';
            break;
           case '/choose-product':
            newTitle = 'Choose your package';
            break;
          case '/home/start':
          case '/home':
          case '/':
          case '/frontpage':
          case '/about':
            break;
          default:
            return;
        }
        this.title.setTitle(newTitle);
      }
    });
  }
}
