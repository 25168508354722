import {Component} from '@angular/core';
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {AboutComponent} from "../../components/about/about.component";
import {MatButton} from "@angular/material/button";
import {ProdDetailsComponent} from "../../components/prod-details/prod-details.component";
import {ReviewsComponent} from "../../components/reviews/reviews.component";
import {RouterLink} from "@angular/router";
import {HeroBgService} from "../../services/hero-bg.service";
import {MatFormField, MatInput} from "@angular/material/input";
import {MatLabel} from "@angular/material/form-field";
import {ApiService} from "../../services/api.service";
import {NgxTurnstileModule} from "ngx-turnstile";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    NgClass,
    AboutComponent,
    MatButton,
    ProdDetailsComponent,
    ReviewsComponent,
    RouterLink,
    NgOptimizedImage,
    MatInput,
    MatFormField,
    MatLabel,
    NgIf,
    NgxTurnstileModule,
    ReactiveFormsModule
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {
  protected contactError: string | undefined;
  private token: string | null = null;
  protected msgControl: FormControl = new FormControl('', [Validators.required, Validators.minLength(6)]);
  protected emailControl: FormControl = new FormControl('', [Validators.email, Validators.required]);
  protected sent: boolean = false;
  constructor(hero: HeroBgService, private apiService: ApiService) {
    hero.setBg('/assets/img/f1-handshake.jpg');
  }


  async submitForm() {
    if (this.emailControl.invalid || this.msgControl.invalid) {
      this.contactError = `Could not send the message: Please fill in your e-mail and message.`;
      return;
    }
    if (!this.token) {
      this.contactError = `Could not send the message: The CAPTCHA was not completed..`;
      return;
    }

    try {
      const r = await this.apiService.contact(
        this.emailControl.value,
        this.msgControl.value,
        this.token
      );
      if (r.success) {
        this.sent = true;
      } else {
        throw new Error(r.msg);
      }
    } catch (e: any) {
      this.contactError = `Could not send the message: ${e.message}`;
    }
  }
  sendCaptchaResponse(captchaResponse: string | null) {
    this.token = captchaResponse;
  }
}
