import { Component } from '@angular/core';
import {NgClass} from "@angular/common";
import {AboutComponent} from "../../components/about/about.component";
import {HeroBgService} from "../../services/hero-bg.service";
import {ReviewsComponent} from "../../components/reviews/reviews.component";
import {MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {ProdDetailsComponent} from "../../components/prod-details/prod-details.component";

@Component({
  selector: 'app-about-page',
  standalone: true,
  imports: [
    NgClass,
    AboutComponent,
    ReviewsComponent,
    MatButton,
    RouterLink,
    ProdDetailsComponent
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutPageComponent {
  constructor(hero: HeroBgService) {
    hero.setBg('/assets/img/f1-handshake.jpg')
  }

}
