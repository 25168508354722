import {Component, Input} from '@angular/core';
import {LocaleService} from "../../services/locale.service";

interface Statbit {
  seq: { [key: string]: number | null },
  value: string,
  valueAnnotation?: string,
  text: string,
  locales: string[]
}

@Component({
  selector: 'app-statbits',
  standalone: true,
  imports: [],
  templateUrl: './statbits.component.html',
  styleUrl: './statbits.component.scss'
})
export class StatbitsComponent {
  @Input() sort: string = 'front';
  public statbits: Statbit[] = [{
    seq: {
      front: 1, ccq: 8, fcf: 6, cyc: 4, tcspro: 8, ffl: 1, ctf: null
    },
    locales: ['en-IE', 'en-GB'],
    value: `${this.localeService.currency()} 10,000+`,
    valueAnnotation: 'per year',
    text: 'Is the average amount car dealers are Over Paying on Forex. Not with TheCarSite. No More Retail Pricing Dressed Up as Institutional.'
  },

    {
      seq: {
        front: 2, ccq: 5, fcf: 7, cyc: 1, tcspro: 8, ffl: 2, ctf: 4
      },
      locales: ['en-IE', 'en-GB'],
      value: 'Dangerously Old Data',
      text: 'Of the Providence Check companies tested we found a totally unsatisfactory amount of dangerously old data. Furthermore, the ‘predictions’ or ability to spot ‘abnormalities’ is abysmal. Not with the CarSite.'
    },

    {
      seq: {
        front: 3, ccq: 7, fcf: 8, cyc: 2, tcspro: 8, ffl: 3, ctf: 5
      },
      locales: ['en-IE', 'en-GB'],
      value: `${this.localeService.currency()} 300+`,
      text: 'Is the average amount dealers spend on car history checks per month. Not with TheCarSite. It’s a Fraction.'
    },

    {
      seq: {
        front: 4, ccq: 6, fcf: 9, cyc: 3, tcspro: 8, ffl: 4, ctf: 6
      },
      locales: ['en-IE', 'en-GB'],
      value: '12 Minutes',
      text: 'Is the average time it takes a dealer to perform a history check. Not with TheCarSite. It’s 60 seconds.'
    },

    {
      seq: {
        front: 5, ccq: 9, fcf: 1, cyc: 5, tcspro: 8, ffl: 5, ctf: 7
      },
      locales: ['en-IE'],
      value: '1 hour',
      text: 'Is the average time it takes a dealer to source, compare, calculate and decide upon a car to import. Not with TheCarSite. It’s minutes.'
    },

    {
      seq: {
        front: 6, ccq: 10, fcf: 4, cyc: 6, tcspro: 8, ffl: 6, ctf: 8
      },
      locales: ['en-IE', 'en-GB'],
      value: `${this.localeService.currency()} 1,000`,
      text: 'Is the average amount of profit dealers make per car after all costs. Not with TheCarSite. It’s Far Greater.'
    },

    {
      seq: {
        front: 7, ccq: 11, fcf: 5, cyc: 7, tcspro: 8, ffl: 7, ctf: 9
      },
      locales: ['en-IE'],
      value: '100 Days',
      text: 'Is the average time it takes from start to finish to sell a car. Not with TheCarSite. It’s Far Faster.'
    }, {
      seq: {
        front: 8, ccq: 1, fcf: null, cyc: 8, tcspro: 8, ffl: 8, ctf: 2
      },
      locales: ['en-IE'],
      value: '10',
      valueAnnotation: 'Minutes',
      text: 'Is the average time it takes a dealer to work out VRT, VAT, Customs, NOx, Forex and Shipping per Car. Not with TheCarSite. It’s 30 seconds.'
    }, {
      seq: {
        front: null, ccq: 1, fcf: null, cyc: null, tcspro: 8, ffl: null, ctf: 5
      },
      locales: ['en-IE', 'en-GB'],
      value: `${this.localeService.currency()} 15`,
      text: 'Is the typical amount charged for VRT, VAT, Customs and NOx calculators. Not with TheCarSite. It’s a Fraction.'
    }, {
      seq: {
        front: null, ccq: 1, fcf: null, cyc: null, tcspro: 8, ffl: null, ctf: null
      },
      locales: ['en-IE', 'en-GB'],
      value: 'ZERO',
      text: 'Is the number of calculators that correctly account for Forex and Shipping which are real costs as you know. Not with TheCarSite. It’s all included and correct.'
    }, {
      seq: {
        front: null, ccq: null, fcf: 3, cyc: null, tcspro: null, ffl: null, ctf: null
      },
      locales: ['en-IE', 'en-GB'],
      value: 'ZERO',
      text: 'Is the number of sites that show importers the fully loaded cost to land a car to Ireland. Not with TheCarSite.'
    }, {
      locales: ['en-IE', 'en-GB'],
      seq: {
        front: null, ccq: 1, fcf: null, cyc: null, tcspro: 8, ffl: null, ctf: null
      },
      value: 'ALL',
      text: 'Is the number of calculators that leave you with the same endless scrolling through Models of vehicles. Pointless. Not with TheCarSite.'
    }, {
      seq: {
        front: 9, ccq: 12, fcf: 2, cyc: 9, tcspro: null, ffl: 9, ctf: null
      },
      locales: ['en-IE'],
      value: '8',
      text: 'Is the average number of sites a dealer visits in order to find, compare and calculate the total cost of a car before purchasing. Not with TheCarSite. It’s just us. '
    }, {
      seq: {
        front: null, ccq: null, fcf: null, cyc: null, tcspro: null, ffl: null, ctf: 1
      },
      locales: ['en-IE', 'en-GB'],
      value: '6 hours',
      text: 'Is the average amount of hours it takes a dealer, per car, to handle the full importation process from start to finish. Not with TheCarSite. It’s Minutes.'
    },

  ];

  constructor(private localeService: LocaleService) {
  }

  public get statbits_sorted(): Statbit[] {
    return this.statbits
      .filter(s => s.seq[this.sort] != null)
      // @ts-ignore
      .sort((sa, sb) => sa.seq[this.sort] - sb.seq[this.sort]);
  }
}
