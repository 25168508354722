import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatAnchor} from "@angular/material/button";
import {RouterLink} from "@angular/router";

@Component({
  selector: "app-subscription-required-dialog",
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatAnchor,
    RouterLink
  ],
  templateUrl: "./subscription-required-dialog.component.html",
  styleUrl: "./subscription-required-dialog.component.scss"
})
export class SubscriptionRequiredDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) protected data: any, protected dialog: MatDialog) {
    if (data["service"]) {
      data["feature"] = this.getTitle(data["service"]);
    }
  }
  private getTitle(service: string) {
    switch (service) {
      case "ccq":
        return "Calculate Cars Quicker";
      case "fcf":
        return "Find Cars Faster";
      case "cyc":
        return "Check Your Car";
      case "ffl":
        return "Forex For Less";
      default:
        return null;
    }
  }
}
