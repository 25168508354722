import { Component } from '@angular/core';
import {NotificationsComponent} from "../../../components/notifications/notifications.component";
import {MatButton} from "@angular/material/button";
import {MatDialog} from "@angular/material/dialog";
import {FcfFiltersComponent} from "../fcf/fcf-filters/fcf-filters.component";
import {FcfService} from "../../../services/fcf.service";

@Component({
  selector: 'app-notifications-home',
  standalone: true,
  imports: [
    NotificationsComponent,
    MatButton
  ],
  templateUrl: './notifications-home.component.html',
  styleUrl: './notifications-home.component.scss'
})
export class NotificationsHomeComponent {
constructor(private dialog: MatDialog, private fcfService: FcfService) {
}
  async new(notifications: NotificationsComponent) {
    const dialog = this.dialog.open(FcfFiltersComponent, {
      data: {
        filtersFormGroup: this.fcfService.initForm(),
        mode: 'notifications'
      },
      id: "fcf-filters"
    });
    const saveSubscription = dialog.componentInstance.savedNotification.subscribe(() => {
      notifications.ngOnInit();
    });
    dialog.afterClosed().subscribe(() => {
      saveSubscription.unsubscribe();
    });
  }
}
