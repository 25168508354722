<app-ffl-content></app-ffl-content>
<section class="usps">
  <h2>Why You Should Use Forex For Less</h2>
  <div class="uspsContainer">
    <div class="usp">
      <img ngSrc="/assets/icons/handshake.svg" height="50" width="50" alt="CCQ">
      <h3>STOP PAYING RETAIL!</h3>
      <div class="usp-content">
        <p>Your current Forex provider is marking up the price and saying ‘low fees’ or ‘no fees’ – there is a markup.
          That is it. Try our calculator for yourself.</p>
        <p>TheCarSite happens to be backed by an ex-Hedge Fund Professional Trader. We have rates the Big Banks would
          pay us for.</p>
        <p>Enough is Enough. Use TheCarSite. Save Money. Compete with the Car Supermarkets.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/piggy-bank.svg" height="50" width="50" alt="Piggybank">
      <h3>More For You.</h3>
      <div class="usp-content">
        <p>Stop paying for retail Forex rates. You wouldn’t pay Retail for a Car. Stop.</p>
        <p>TheCarSite will beat any Forex rate you can find. Fact.</p>
        <p>All We Want is to Help You. To Be Your Partner. To Grow Together.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/customer-service.svg" height="50" width="50" alt="Support">
      <h3>We Are With You.</h3>
      <div class="usp-content">
        <p>We want to provide you with the best Forex rates and solutions available to anyone Banks included.</p>
        <p>We will never allow our prices or service to be beaten and will help you with SMART Forex solutions to
          guarantee Price Certainty.</p>
        <p>We Are Here to Help.</p>
      </div>
    </div>
  </div>
  <button class="c2aLarge" mat-raised-button color="primary" role="link" routerLink="/register">Get Started</button>
</section>
<section class="reviews">
  <h2>What Our Customers Just Like You Say</h2>
  <app-reviews></app-reviews>
</section>
<section class="valueProp">
  <h2>What You Get From Forex For Less</h2>
  <div class="valueProps">
    <div>
      <img ngSrc="/assets/icons/money-bag.svg" width="16" height="16" alt="Money">
      <p>The Best Pricing with Huge Savings</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/user-friendly.svg" width="16" height="16" alt="Easy">
      <p>The Easiest to Use.</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/piggy-bank.svg" width="16" height="16" alt="Money">
      <p>More Money In Your Pocket.</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/guarantee.svg" width="16" height="16" alt="Money">
      <p>No Nonsense</p>
    </div>
  </div>
  <button class="c2aLarge" mat-raised-button role="link" routerLink="/register">Get Started</button>
</section>
<section class="stats">
  <h2>Why You Need Forex For Less</h2>
  <app-statbits sort="ffl"></app-statbits>
  <button class="c2aLarge" mat-raised-button role="link" color="primary" routerLink="/register">Get Started</button>
</section>
<section class="hero  prods">
  <div class="prods">
    <app-products exclude="ffl"></app-products>
  </div>
</section>
