<h2 mat-dialog-title>You Have Hit Your Fair Usage Cap for CYC</h2>
<mat-dialog-content>
  <p>You Have Hit Your Fair Usage Cap for CYC on Your Current Plan ({{userService.plan()}}).</p>
  <p>You still have full usage of all other products including CCQ, FCF and FFL.</p>
  <p>If you wish to make further CYC searches we have two options for you.</p>
</mat-dialog-content>
<mat-dialog-actions>
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="upgrade()">Upgrade Your Plan</button>
  <button mat-raised-button color="primary" (click)="buyCredits()">Buy additional credits</button>
  <span class="spacer"></span>
</mat-dialog-actions>

