import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'engineType', standalone: true
})
export class EngineTypePipe implements PipeTransform {
  static lookup(value: string | number): string {
    switch (Number(value)) {
      case 1:
        return "Petrol";
      case 2:
        return "Diesel";
      case 3:
        return "Electric";
      case 10:
      case 15:
        return "Diesel / electric hybrid";
      case 16:
        return "Petrol / electric hybrid";
      default:
        return "Other";
    }
  }

  transform(value: string | number): string {
    return EngineTypePipe.lookup(value);
  }
}
