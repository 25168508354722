import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {
  DecimalPipe,
  KeyValue,
  KeyValuePipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgStyle,
  ViewportScroller
} from "@angular/common";
import { FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSelect} from "@angular/material/select";
import {errorsToStrings} from "../ccq/ccq-home.component";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {FormatCheckKeyPipe} from "../../../pipes/format-check-key.pipe";
import {formatRes} from "../../../pipes/format-res.pipe";
import {formatKey} from "../../../pipes/format-key.pipe";
import {ApiResponse, ApiService} from "../../../services/api.service";
import {StatbitsComponent} from "../../../components/statbits/statbits.component";
import {VrmSearchComponent} from "../../../components/vrm-search/vrm-search.component";
import {MatAccordion, MatExpansionPanel, MatExpansionPanelHeader} from "@angular/material/expansion";
import {MatSlideToggle, MatSlideToggleChange} from "@angular/material/slide-toggle";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {HeroBgService} from "../../../services/hero-bg.service";
import {UserActivityComponent} from "../../../components/user-activity/user-activity.component";
import {DisclaimerComponent} from "../../../components/disclaimer/disclaimer.component";
import {FflContentComponent} from "../../../components/ffl-content/ffl-content.component";
import {ProductsComponent} from "../../../components/products/products.component";
import {MatIcon} from "@angular/material/icon";
import {BugService} from "../../../services/bug.service";
import {HttpErrorResponse} from "@angular/common/http";

interface CheckResponse {
  title: string;
  loading: boolean | null;
  uk: boolean, // Whether to run this check for UK cars
  ie: boolean, // Whether to run this check for IE cars
  error?: string | null;
  result?: {
    tableData?: any;
    status: "ok" | "warn" | "fail" | null;
    text?: string[];
  }
}

interface CheckResponses {
  [p: string]: CheckResponse
}

@Component({
  selector: "app-check",
  standalone: true,
  imports: [DecimalPipe, FormsModule, KeyValuePipe, MatButton, MatError, MatFormField, MatInput, MatLabel, MatOption, MatProgressSpinner, MatSelect, NgForOf, NgIf, ReactiveFormsModule, errorsToStrings, formatKey, formatRes, FormatCheckKeyPipe, NgClass, NgStyle, NgOptimizedImage, RouterLink, StatbitsComponent, VrmSearchComponent, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatSlideToggle, UserActivityComponent, DisclaimerComponent, FflContentComponent, ProductsComponent, MatIcon],
  templateUrl: "./cyc-home.component.html",
  styleUrl: "./cyc-home.component.scss"
})
export class CycHomeComponent implements OnInit{
  // public searchControl: FormControl;
  public searchResult: { [key: string]: string | boolean | number | null } | undefined;
  public detailsError: string | undefined;
  public detailsLoading: boolean = false;
  public curLoc: "uk" | "ie" = "uk"; // Default vehicle location
  protected searching: boolean = false;
  protected motivationalText: string | undefined;
  protected checks: CheckResponses = {
    identity: { title: "Vehicle Identity", loading: null, uk: true, ie: false },
    stolen: { title: "Stolen Vehicle",  loading: null, uk: true, ie: false  },
    mileage: { title: "Mileage History",  loading: null, uk: true, ie: false  },
    owners: { title: "Owner History",  loading: null, uk: true, ie: false  },
    plates: { title: "Number Plate History",  loading: null, uk: true, ie: false  },
    mot: { title: "MOT History",  loading: null, uk: true, ie: false  },
    valuation: { title: "Vehicle Valuation",  loading: null, uk: true, ie: true  },
    costs: { title: "Running Costs",  loading: null, uk: true, ie: false  },
    warranty: { title: "Vehicle Warranty",  loading: null, uk: true, ie: true  },
    taxed: { title: "Tax status",  loading: null, uk: true, ie: false  },
    finance: { title: "Finance Status",  loading: null, uk: true, ie: true  },
    writeoff: { title: "Write-off status",  loading: null, uk: true, ie: false  },
    damages: { title: "Vehicle Damages",  loading: null, uk: true, ie: false  },
    recall: { title: "Outstanding recalls",  loading: null, uk: false, ie: false  },
    provenance: { title: "High risk provenance",  loading: null, uk: true, ie: false  },
    insurance: { title: "Insurance cover",  loading: null, uk: false, ie: false  }
  };
  @ViewChild("vrmSearch") vrmSearch: VrmSearchComponent | undefined;
  constructor(protected bug: BugService,private scroller: ViewportScroller, private route: ActivatedRoute, private apiService: ApiService, @Inject(LOCAL_STORAGE) private storage: StorageService, private hero: HeroBgService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(p => {
      if (p["url"]) {
        this.vrmSearch?.setVrm(p["url"]);
        this.search(p["url"]);
      }
    });
    this.hero.setBg('/assets/img/mechs.png');
    }
  async search(vrm: string): Promise<boolean> {

    this.searching = true;
    this.motivationalText = "Looking for the vehicle.";
    this.reset();
    try {
      const r = <ApiResponse>await this.apiService.search(vrm);
      if (r?.success) {
        this.searchResult = r.data;
        this.curLoc = r.data.loc;
        this.motivationalText = "Found the vehicle. Looking for more data.";
        // Scroll to report
        this.scroller.scrollToAnchor("cycStart");
        await this.getDetails(r.data.loc, r.data.id);

        await this.loadChecks(r.data.loc, r.data.id);
      }
        this.searching = false;
        return true;
    } catch(e: any) {
        // console.error(e);
        this.reset();
        this.vrmSearch?.setError( e.error.msg ?? e.message);
        this.searching = false;
        return false;
      }
  }
  @ViewChild('financeCheck') financeCheck!: MatSlideToggle;
  private async loadChecks(loc: "uk" | "ie", id: number) {
    for (let key in this.checks) {
      if (key === 'finance' && !this.financeCheck.checked) {
        // We are not doing finance check
        continue;
      }
      // Set loaders
      if (this.checks[key][loc]) {
        this.checks[key].loading = true;
      }
    }
    // We are not doing finance check
    if (!this.financeCheck.checked) {
      this.checks["finance"].result = {
        status: null, tableData: null,
        text: [
          "You have opted to not run finance checks on this vehicle.",
          "Please use the toggle under the search input if you wish to enable it."
        ]
      }
    } else {
      this.checks["finance"].result = undefined;
    }
    // Load checks
    for (let key in this.checks) {
      if (key === 'finance' && !this.financeCheck.checked) {
        continue;
      }
      if (this.checks[key][loc]) {
        this.motivationalText = `Checking the ${this.checks[key].title}`;
        // await this.sleep(400);
        await this.check(loc, id, key);
        this.checks[key].loading = false;
      }
    }
  }


  private async getDetails(loc: "uk" | "ie", vehicle_id: number) {
    const r = await this.apiService.getDetails(loc,vehicle_id);
    if (r?.success) {
      this.searchResult = r?.data;
      if (this.searchResult) {
        this.searchResult['loc'] = loc;
      }
      if (r.data.registrationNumber) {
        this.vrmSearch?.setVrm(r.data.registrationNumber);
      } else if (r.data.registrationNumber_ie) {
        this.vrmSearch?.setVrm(r.data.registrationNumber_ie);
      }
    } else {
      this.detailsError = r?.msg;
    }
    this.detailsLoading = false;
  }

  private reset() {
    for (let key in this.checks) {
      this.checks[key].result = undefined;
      this.checks[key].error = undefined;
    }
    this.vrmSearch?.resetError();
    this.searchResult = undefined;
  }

  private async check(loc: "uk" | "ie", id: number, check: string) {
    const r = await this.apiService.check(loc,id, check).catch((e: HttpErrorResponse) => {
      if (e.status === 402) {
        throw e;
      }
      console.error(e);
    })
    if (r?.success) {
      this.checks[check].result = r?.data;
    } else {
      this.checks[check].error = r?.msg;
    }
  }
  origOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  /**
   * Store the finance check toggle preference in local storage
   * @param $event
   */
  async toggleFinance($event: MatSlideToggleChange) {
    this.storage.set('financePref', $event.checked);
    if ($event.checked && this.searchResult) {
      // We have a search - run the finance check
      // console.log('We have a search - run the finance check', this.searchResult);
      this.checks['finance'].loading = true;
      await this.check(<'uk' | 'ie'>this.searchResult['loc'], <number>this.searchResult['id'], 'finance');
      this.checks['finance'].loading = false;
    }
  }

  /**
   * Get the finance check toggle state preference
   * If none has been set, default to true (checked)
   */
  getFinancePref(): boolean {
    return <boolean>this.storage.get('financePref') ?? false;
  }

  protected readonly Number = Number;
}

