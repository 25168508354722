import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {DecimalPipe, KeyValuePipe, NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {MatPaginator, MatPaginatorModule, PageEvent} from "@angular/material/paginator";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSort, MatSortModule, Sort} from "@angular/material/sort";
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  MatDialog
} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {MilesToKmPipe} from "../../../../pipes/miles-to-km.pipe";
import {formatRes} from "../../../../pipes/format-res.pipe";
import {CurrencyService} from "../../../../services/currency.service";
import {StatbitsComponent} from "../../../../components/statbits/statbits.component";
import {ProdDetailsComponent} from "../../../../components/prod-details/prod-details.component";
import {FcfService, SortCriteria} from "../../../../services/fcf.service";
import { FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {HeroBgService} from "../../../../services/hero-bg.service";
import {FcfFiltersComponent} from "../fcf-filters/fcf-filters.component";
import {CarDetailsDialog} from "../dialog-car-details/dialog-car-details";
import {MatToolbar, MatToolbarRow} from "@angular/material/toolbar";
import {MatIcon} from "@angular/material/icon";
import {DisclaimerComponent} from "../../../../components/disclaimer/disclaimer.component";
import {Subscription} from "rxjs";

interface VRT {
  bca_id: number;
  registrationNumber: string;
  id: number,
  make: string,
  model: string,
  transmissionType: number,
  numberOfDoors: number,
  engineType: number,
  bodyType: number,
  engineCapacity: number,
  statCode: number,
  mileage: number,
  yearOfRegistration: number,
  euroStatus: number,
  versionData: string,
  vrtEuro: number,
  omsp_current: number,
  attemptedVrt_ts: string, // 2024-02-19 00:24:32
  astonbarclay_id: number | null,
  manheim_id: number | null,
  autotrader_id: number | null,
  priceGBP: number,
  priceEst: number,
  priceEurExVat: number,
  priceEstExVat: number,
  shippingEur: number,
  customsDuty: number,
  profit: number,
  exchange_rate: number,
  roi: number // -0.17
}

@Component({
  selector: "app-vrt",
  standalone: true,
  imports: [
    MatTableModule,
    MatToolbarRow,
    MatPaginatorModule,
    NgIf,
    DecimalPipe,
    MatProgressSpinner,
    MatSortModule,
    MilesToKmPipe,
    formatRes,
    MatTooltipModule,
    NgClass,
    MatButton,
    RouterLink,
    NgOptimizedImage,
    StatbitsComponent,
    ProdDetailsComponent,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    KeyValuePipe,
    MatLabel,
    MatToolbar,
    MatIcon,
    DisclaimerComponent,
  ],
  templateUrl: "./fcf-home.component.html",
  styleUrl: "./fcf-home.component.scss",
})
export class FcfHomeComponent implements AfterViewInit, OnInit, OnDestroy {
  public filtersFormGroup!: FormGroup;
  public displayedColumns = [
    "make",
    "yearOfRegistration",
    "mileage",
    "engineType",
    "priceEurExVat",
    "priceEstExVat",
    "vrtEuro",
    "vat",
    "shippingEur",
    "customsDuty",
    "profit",
    "roi",
  ];
  public vrt = new MatTableDataSource<VRT>([]);
  public loading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild("modelsFilter") modelsFilter!: MatSelect;
  total = 0;

  protected errorMsg: string | undefined;
  protected init: boolean = false;
  protected readonly Number = Number;
  private curSortCriteria: SortCriteria | undefined;
  private submitSubscription: Subscription | undefined;
  private resetSubscription: Subscription | undefined;

  constructor(
    public dialog: MatDialog,
    protected currency: CurrencyService,
    public fcfService: FcfService,
    private hero: HeroBgService
  ) {
    this.filtersFormGroup = this.fcfService.initForm();
  }

  ngOnDestroy(): void {
    this.submitSubscription?.unsubscribe();
    this.resetSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.hero.setBg("/assets/img/fast.jpg", "center center");
  }

  ngAfterViewInit() {
    if (this.paginator && this.sort) {
      // this.vrt.paginator = this.paginator;
      this.vrt.sort = this.sort;
    }
    this.getData(this.paginator?.pageSize, 1).catch(e => {
      this.errorMsg = `Could not fetch data due to an error: ${e.message}`;
      console.error(e);
    });
  }
  openFilterDialog() {
    const filterDialog = this.dialog.open(FcfFiltersComponent, {
      closeOnNavigation: true,
      data: {
        mode: "fcf",
        filtersFormGroup: this.filtersFormGroup,
      },
      id: "fcf-filters",
    });
    this.submitSubscription = filterDialog.componentInstance.submit.subscribe(async () => {
      filterDialog.close();
      await this.getData(this.paginator?.pageSize, 1).catch(e => {console.error(e)});
    });
    this.resetSubscription = filterDialog.componentInstance.reset.subscribe(() => {
      this.filtersFormGroup = this.fcfService.initForm();
      filterDialog.componentInstance.data.filtersFormGroup = this.filtersFormGroup;
    });
  }

  openDialog(data: VRT) {
    this.dialog.open(CarDetailsDialog, {
      data: data,
      id: "car-details",
    });
  }

  announceSortChange($event: Sort) {
    // console.log("Sorting:", $event);
    if (this.paginator) {
      // Goto page 1 on new sort
      this.paginator.pageIndex = 0;
      this.curSortCriteria = {
        active: $event.active,
        direction: $event.direction,
      };
      this.getData(this.paginator.pageSize, 1);
    }
  }

  handlePageEvent(event: PageEvent) {
    if (this.paginator) {
      this.paginator.pageIndex = event.pageIndex;
      this.paginator.pageSize = event.pageSize;
      // this.paginator.length = this.fcfService.total;
    }
    this.getData(event.pageSize, event.pageIndex + 1);
  }


  async getData(pageSize = 50, pageIndex = 1) {
    this.loading = true;
    this.vrt.data = await this.fcfService.get(
      pageSize,
      pageIndex,
      this.curSortCriteria,
      this.filtersFormGroup.value
    );
    this.total = this.fcfService.total;
    // console.log('total', this.total);
    this.loading = false;
  }


}




