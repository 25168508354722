<app-header></app-header>
<div class="mainContent">
  @if (loaded) {
    <!--  <app-access *ngIf="!userService.isLoggedIn()"></app-access>-->
    <router-outlet/>
  } @else {
    <div class="loader">
      <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
    </div>
  }
</div>
<app-footer></app-footer>
<router-outlet name="overlay"></router-outlet>
