import {Component, Inject, OnInit} from "@angular/core";
import {MatButton} from "@angular/material/button";
import { RouterLink} from "@angular/router";
import {HeroBgService} from "../../../services/hero-bg.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ApiResponse, ApiService} from "../../../services/api.service";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {UserService} from "../../../services/user.service";

@Component({
  selector: "app-success",
  standalone: true,
  imports: [
    MatButton,
    RouterLink,
    MatProgressSpinner
  ],
  templateUrl: "./success.component.html",
  styleUrl: "./success.component.scss"
})
export class SuccessComponent implements OnInit {
  private paymentResult: ApiResponse | undefined;
  protected success: boolean | undefined;
  public constructor(hero: HeroBgService, private apiService: ApiService,@Inject(LOCAL_STORAGE) private storage: StorageService, private userService: UserService) {
    hero.setBg("/assets/img/f1-handshake.jpg", "center 30%");
  }
  ngOnInit() {
    // Get the payment id from local storage
    const session_id = this.storage.get("session_id");
    if (!session_id) {
      this.paymentResult = {
        success: false,
        msg: "We could not find the payment. Did you use another browser or private window to initiate the payment? NB: The payment may still have been successful. Please check your e-mail for a receipt, and try logging out and back in to activate your subscription.",
        data: null
      }
      this.success = false;
      return;
    }
    this.checkStatus(session_id);
  }

  // get success(): boolean | undefined {
  //   return this.paymentResult?.success;
  // }
  get paymentError(): string {
    return this.paymentResult?.msg ?? "Unspecified error";
  }

  private async checkStatus(session_id: string) {
    this.paymentResult = await this.apiService.getPaymentStatus(session_id);
    this.success = this.paymentResult.success;
    if (this.success) {
      // Payment success, refresh firebase user
      await this.userService.refresh();
    }
  }
}
