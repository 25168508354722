<h1>My quotes</h1>
<div class="card">
  <p>If you have requested a quote, you will find it in the overview below</p>
  @if (loading) {
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
  } @else if (quotesDataSource.data.length > 0) {
    <table mat-table [dataSource]="quotesDataSource">
      <ng-container matColumnDef="ts">
        <th mat-header-cell *matHeaderCellDef>Time</th>
        <td mat-cell *matCellDef="let element">{{ element.ts }}</td>
      </ng-container>
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef>Vehicle</th>
        <td mat-cell *matCellDef="let element">{{ element.make }} {{ element.model }} {{ element.version }}</td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let element" class="euro">{{ element.total | number : '1.2-2' : 'en' }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="clickable" (click)="quoteService.openDialog(element)"></tr>
    </table>
  } @else {
    <p class="error">No quotes found..</p>
  }
</div>
