<section class="header">
  <h1><span>One Partner. Every Solution.</span><span>One Fair Fee. All in One Place.</span></h1>
  <h2>We are TheCarSite for You.</h2>
</section>
<section class="hero">
<!--  <app-pricetag [price]="price" [prodName]="prodName"></app-pricetag>-->
  <app-prod-details [showPriceTag]="false" [feature]="true"></app-prod-details>
  <app-price-compact [service]="'tcspro'"></app-price-compact>
</section>
<section class="usps">
  <h2>Why You Should Use {{prodName}}</h2>
  <div class="uspsContainer">
    <div class="usp">
      <img ngSrc="/assets/icons/stopwatch.svg" height="50" width="50" alt="CCQ">
      <h3>Save Time</h3>
      <div class="usp-content">
        <p>Calculate Cars Quicker.</p>
        <p>Calculate Every Import Cost in 30 seconds MAX.</p>
        <p>TheCarSite is here to save you time and make you real money.</p>
        <p>Start Saving Time Now and Calculate Cars Quicker.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/zoom.svg" height="50" width="50" alt="CYC">
      <h3>Check Your Car More Accurately</h3>
      <div class="usp-content">
        <p>Superior Car History Checks For Less</p>
        <p>You pick the budget. We find the cars with the highest ROI that sell the FASTEST. We could say more but we will let the product do the talking.</p>
        <p>Accurate Data at Fair Prices with TheCarSite.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/money-bag.svg" height="50" width="50" alt="FCF">
      <h3>Find Cars Faster</h3>
      <div class="usp-content">
        <p>Find the Ideal Cars to Import Based on Budget and Projected ROI</p>
        <p>Stop Scrolling. Stop Searching. Let Your Money Work for You.</p>
        <p>TurboCharge Your ROI and Save Time with Find Cars Faster.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/currencies.svg" height="50" width="50" alt="FFL">
      <h3>Forex For Less</h3>
      <div class="usp-content">
        <p>Stop Paying Retail Dressed Up As Trade Forex Rates.</p>
        <p>TheCarSite offers truly institutional Forex rates the Commercial Banks would die for.</p>
        <p>Save Money. We could say more but what’s the point. Save Money with TheCarSite.</p>
      </div>
    </div>
  </div>
  <button class="c2aLarge" mat-raised-button color="primary" role="link" routerLink="/register">Get Started</button>
</section>
<section class="reviews">
  <h2>What Our Customers Just Like You Say</h2>
  <app-reviews></app-reviews>
</section>
<section class="valueProp">
  <h2>What You Get From Find Cars Faster</h2>
  <div class="valueProps">
    <div>
      <img ngSrc="/assets/icons/relax.svg" width="16" height="16" alt="Time">
      <p>Time. Time. Time. Time.</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/customer-service.svg" width="16" height="16" alt="Ideal cars">
      <p>The Ideal Cars presented to You</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Money">
      <p>Projected Profit</p>
    </div>
    <div>
      <div class="img">ROI</div>
      <p>Projected ROI</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Money">
      <p>Total Cost to Land</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/stopwatch.svg" width="16" height="16" alt="Stopwatch">
      <p>Average Time to Sell</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Magnifying glass">
      <p>‘Funny Stuff’ Flagged (Before History Checks)</p>
    </div>
    <div>
      <div class="img">VRT</div>
      <p>VRT Calculated for You</p>
    </div>
    <div>
      <div class="img">VAT</div>
      <p>VAT Calculated for You</p>
    </div>
    <div>
      <div class="img">NOx</div>
      <p>NOx Calculated for You</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/currencies.svg" width="16" height="16" alt="Currency">
      <p>Forex Calculated for You</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/ship.svg" width="16" height="16" alt="Ship">
      <p>Shipping Calculated for You</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/cars.svg" width="16" height="16" alt="Cars">
      <p>Browse cars using dealer specific functionality</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Search">
      <p>Search based on your criteria</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/alert.svg" width="16" height="16" alt="Alert">
      <p>Alerts sent for your ideal cars</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/handshake.svg" width="16" height="16" alt="Alert">
      <p>Importing Cars Made Easy</p>
    </div>
  </div>
  <button class="c2aLarge" mat-raised-button role="link" routerLink="/register">Get Started</button>
</section>
<section class="stats">
  <h2>Why You Need {{prodName}}</h2>
  <app-statbits sort="fcf"></app-statbits>
  <button class="c2aLarge" mat-raised-button role="link" color="primary" routerLink="/register">Get Started</button>
</section>
<section class="hero prods">
  <div class="prods">
    <app-products exclude="fcf"></app-products>
  </div>
</section>
